<template>
    <div class="page-wrapper project-page">
        <ArticleHeader :content="content"/>
        <component
            v-for="(component, index) in content.contentBlocks"
                :is="component.name"
                :content="component.data"
                :index="index"
                :nextComponentType="content.contentBlocks[index+1] ? content.contentBlocks[index+1].name : null"
                :key="component.name+'_'+index"
        />
        <section class="default-layout more-projects" v-if="news">
            <NewsOverview :content="news" :title="'Meer nieuws'" />
        </section>
     </div>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'
    import buttonArrow from '@/js/svg/buttonArrow.vue'
    import ArticleHeader from '@/js/components/Article/Header.vue'

    export default {
        mixins: [DefaultPage],
        components: {
            ArticleHeader,
            buttonArrow
        },
        data: function() {
            return {
              news: [],
            }
        },
        computed: {
        },
        methods: {
            fetch() {
                console.log(this.content.id);
                this.$http.get("/api/v1/news.json?limit=3&not="+this.content.id)
                    .then(response => {
                        this.news = response.data.data;
                    });
                },
        },
        mounted() {
            this.fetch();
        },
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';
    .project-page {

        .image-component {
            .img-wrapper {
                background: transparent !important;

                .content {
                    @include grid-12;

                    img {
                        background-color: $grey;
                        grid-column: 1/ span 12;
                        @include tablet {
                            grid-column: 3/ span 8;
                        }
                    }
                }
            }
        }

        .more-projects {
            margin-bottom: $defaultPadding*3.5;

            h4 {
                @include font-big;
            }

            .button-wrapper {
                display: flex;
                justify-content: flex-end;
            }

            @include tablet {
                .button-wrapper {
                    margin-top: -#{$defaultPadding*1.5};
                }
            }
        }
    }
</style>

