<template>
    <span class="article-header-wrapper">
        <div class="default-layout">
            <header class="content">
                <div class="text">
                    <div class="text-wrapper">
                        <ul class="related-pages">
                            <router-link v-for="(relatedPage, index) in content.relatedPages" class="label" :key="'relatedPage_'+index" tag="li" :to="{ path: relatedPage.uri }">
                                {{ relatedPage.title }}
                            </router-link>
                        </ul>
                        <span>
                            <div class="header-tags">
                                <span v-if="content.newsType" v-text="content.newsType"></span>
                                <span v-if="content.newsTheme" v-text="content.newsTheme"></span>
                                <span v-if="content.newsSector" v-text="content.newsSector"></span>
                            </div>
                            <h1>{{ content.title }}</h1>
                            <span class="article-date">{{ content.postDate }}</span>
                        </span>
                    </div>
                </div>
            </header>
        </div>
    </span>
</template>

<script>
    import Intersect from 'vue-intersect';

    export default {
        components: {
            Intersect,
        },
        props: {
            content: {
                type: Object | Array,
                required: true
            }
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .article-header-wrapper {
        display: block;
        background-color: $blue;
        overflow: hidden;

        .default-layout {
            .background {
                width: 100%;
                overflow: hidden;
            }

            header {
                width: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;

                .text {
                    position: relative;
                    @include grid-12;

                    .text-wrapper {
                        @include fluid-type(min-height, 480px, 1600px, 250px, 350px);
                        background-color: $blue;
                        position: relative;
                        z-index: 1;
                        grid-column: 1/ span 12;
                        display: flex;
                        flex-flow: row wrap;
                        align-content: space-between;
                        padding: $defaultPadding*1.4 $defaultPadding;

                        .related-pages {
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            display: flex;
                            flex-flow: row wrap;
                            margin-bottom: $defaultPadding*1.5;

                            .label {
                                cursor: pointer;
                                border: 1px solid $blue-light;
                                border-radius: 15px;
                                color: $blue-light;
                                padding: $defaultPadding/3 $defaultPadding/2 0 $defaultPadding/2;
                                margin-right: $defaultPadding/2.5;
                                margin-bottom: $defaultPadding/2;
                                text-decoration: none;

                                transition: all .15s cubic-bezier(0.65, 0, 0.35, 1);

                                &:hover {
                                    color: $white;
                                    border-color: $white;
                                }
                            }
                        }

                        .header-tags {
                            display: flex;
                            margin-bottom: 40px;

                            span {
                                margin-right: 10px;
                                border-radius: 100vw;
                                border: 1px solid white;
                                padding: 7px 12px 5px 12px;
                                color: white;
                            }
                        }

                        h1 {
                            @include font-xl;
                            color: $white;
                            padding-bottom: 0;
                        }

                        .article-date {
                            display: block;
                            margin-top: 20px;
                            color: white;
                        }
                    }

                    @include tablet {

                        .text-wrapper {
                            grid-column: 3/ span 8;
                            padding-top: 80px;
                            padding-bottom: 140px;
                        }
                    }
                }
            }
        }
    }
</style>

