<template>
    <div class="main-content">
        <component v-if="content" :is="pageType" :content="content" :key="'page_'+content.id"></component>
    </div>
</template>

<script>
    import home from './pageTypes/Home'
    import single from './pageTypes/Single'
    import project from './pageTypes/Project'
    import projectsOverview from './pageTypes/ProjectsOverview'
    import Services from './pageTypes/Services'
    import newsArticle from './pageTypes/NewsArticle.vue'
    import newsOverview from './pageTypes/NewsOverview'
    import Sector from './pageTypes/Sector'
    import Expertise from './pageTypes/Expertise'
    import contact from './pageTypes/Contact'
    import hubspotForm from './pageTypes/HubspotForm'
    import PageNotFound from './PageNotFound'

    import { mapMutations } from 'vuex'

    export default {
        components: {
            // Layouts
            home,
            single,
            projectsOverview,
            project,
            Services,
            newsArticle,
            newsOverview,
            Sector,
            Expertise,
            contact,
            hubspotForm,
            PageNotFound
        },
        data: function() {
            return {
                loading: true,
                content: {},
                pageType: 'single'
            }
        },
        computed: {
            pageLoading: {
                get () {
                    return this.$store.state.pageLoading;
                },
                set (status) {
                    return this.$store.commit('setPageLoading', status);
                }
            }
        },
        methods: {
            ...mapMutations([
                'setAvailableTranslations',
                'setTheme'
            ]),
            loadContent: function(to) {
                this.pageLoading = true;

                return new Promise((resolve, reject) => {
                    const startTime = Date.now();                                    

                    const urlPart1 = to ? to.params.part1 : this.$route.params.part1,
                          urlPart2 = to ? to.params.part2 : this.$route.params.part2,
                          availableLanguages = this.$store.state.availableLanguages;

                    let slug = null,
                        content = null;

                    // Check if part 1 of the url is language then set part 2 as the slug or get the homepage if part 2 is empty
                    if(availableLanguages.includes(urlPart1)) {
                        if(urlPart2) {
                            slug = urlPart2;
                        } else {
                            slug = 'home';
                        }
                    } else if(urlPart1) {
                        slug = urlPart1;
                    } else {
                        slug = 'home'
                    }                                                        

                    let currentLanguage     = this.$store.state.currentLanguage;
                    let pageContentEndpoint = this.$store.state.apiEndpoints['page'].url+slug+'.json?lang='+currentLanguage;

                    this.$http.get(pageContentEndpoint, {crossdomain: true})
                        .then((result) => {
                            // Check if enough time has passed to play the loading animation
                            const endTime = new Date(),
                                    timeDiff = endTime - startTime;

                            const timeout = Math.max(0, 600-timeDiff);

                            if(slug === 'hubspot-form') {
                                var meta = document.createElement('meta');
                                meta.name = "robots";
                                meta.content = "noindex,nofollow";
                                meta.id = 'hs-meta-tag';
                                document.head.appendChild(meta);
                            } else {
                                var meta = document.getElementById('hs-meta-tag');
                                if(meta) {
                                    meta.parentNode.removeChild(meta);
                                }
                            }
                            setTimeout(() => {
                                window.scrollTo(0,0);

                                // Set the content to the view component
                                this.setContent(result.data).then(() => {
                                    this.pageLoading = false;
                                    resolve();
                                });
                            }, timeout);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            },
            setContent: function(content) {
                if(content) {
                    return new Promise((resolve, reject) => {
                        if (content.type == 'home') {
                            this.$store.commit('setIsHome', true);
                        } else if(this.$store.state.isHome) {
                            this.$store.commit('setIsHome', false);
                        }

                        // const singleTypes = [];
                        // let pageType = null;

                        // if (singleTypes.indexOf(content.type) > -1) {
                        //     pageType = 'single';
                        // }

                        // this.pageType = pageType ? pageType : content.type;
                        this.pageType = content.type;
                        this.content  = content;

                        this.setAvailableTranslations(content.translations);
                        this.setMeta(content.meta);
                        this.setStyle(content.styles, content.type);

                        resolve();
                    });

                }
            },
            setMeta: function(meta) {
                // Set page title
                if(this.content && this.content.title) {                    
                    const baseTitle      = this.$store.state.websiteData.title,
                          titleSeperator = this.$store.state.websiteData.titleSeperator,
                          pageTitle      = this.content.title;

                    if(this.content.title =='Home') {
                        document.title = `${baseTitle}`;
                    } else {
                        document.title = `${baseTitle} ${titleSeperator} ${pageTitle}`;
                    }
                    
                }

                // Set meta information if set
                if(meta) {
                    if(document.querySelector('meta[name="description"]')) {
                        if(meta.description) {
                            document.querySelector('meta[name="description"]').setAttribute("content", meta.description);
                        } else if(this.$store.state.websiteData && this.$store.state.websiteData.metaDescription) {
                            document.querySelector('meta[name="description"]').setAttribute("content", this.$store.state.websiteData.metaDescription);
                        }
                    }
                }
            },
            setStyle(styles, pageType) {
                // If a color is set on the current entry it overrides automated colors
                if(styles.bgColor) {
                    if (styles.bgColor == 'blue') {
                        this.$store.commit('setMenuBgClass', 'blue');
                    } else if (styles.bgColor == 'blue-lighter') {
                        this.$store.commit('setMenuBgClass', 'blue-lighter');
                    } else {
                        this.$store.commit('setMenuBgClass', 'white');
                    }

                    return;
                }

                // Set a menu bg color based on page type
                const blueMenuBG = ['home', 'newsArticle'];
                const blueLighterMenuBG = ['projectsOverview','contact', 'hubspotForm', 'newsOverview'];

                if (blueMenuBG.includes(pageType)) {
                    this.$store.commit('setMenuBgClass', 'blue');
                } else if (blueLighterMenuBG.includes(pageType)) {
                    this.$store.commit('setMenuBgClass', 'blue-lighter');
                } else {
                    this.$store.commit('setMenuBgClass', 'white');
                }

            }
        },
        created() {
            window.scrollTo(0,0);
            this.loadContent().catch((error) => {
                if(window.location.href.indexOf("404") == -1) {
                    this.$router.push({ path: '404' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            this.loadContent(to).then(() => {
                this.$store.commit('setMenuLogoVisibility', true);
                next();
            })
            .catch((error) => {
                if(window.location.href.indexOf("404") == -1) {
                    this.$router.push({ path: '404' });
                } else {
                     this.pageLoading = false;
                }
            });
        },
        beforeDestroy() {

        }
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';
    
    .main-content {
        .page-wrapper {
            min-height: 100vh;
        }
    }
</style>