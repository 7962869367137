<template>
    <div class="page-wrapper error-404">
       <span>
           404
       </span>
    </div>
</template>

<script>
    import DefaultPage from '../mixins/DefaultPage.vue'
    
    export default {
        mixins: [DefaultPage],
        components: {
        }
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .page-wrapper.error-404 {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-med;
        font-weight: 500;

        > span {
            position: relative;
            padding: $defaultPadding*2;
        }

    }
</style>

