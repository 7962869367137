<template>
    <div class="page-wrapper contact">
        <div class="default-layout">
            <h1>Contact</h1>
            <div class="contact-content" ref="content">
                <div v-for="(block, index) in content.contentBlocks" :class="block.type" :key="'contentBlock-'+index">
                    <h2 v-if="block.type == 'title'">{{ block.body }}</h2>
                    <ContactForm v-else-if="block.type == 'contactForm'" :content="block"></ContactForm>
                    <address v-else v-html="block.body"></address>
                </div>
            </div>
        </div>
        <CtaComponent v-if="content.cta" :content="content.cta"/>
    </div>
</template>

<script>
    import ContactForm from '@/js/components/contentBlocks/ContactForm.vue';
    import DefaultPage from '../../mixins/DefaultPage.vue'

    export default {
        mixins: [DefaultPage],
        components: {
            ContactForm,
        },
        methods: {
            trackClick() {
                // Track clicks to fbq on all links in the content $ref.
                if(!window.fbq) return;
                this.$refs.content.querySelectorAll('a').forEach((link) => {
                    link.addEventListener('click', () => {
                       window.fbq('track', 'Lead', {url: link.href});
                    });
                });
            },
        },
        mounted() {
            this.trackClick();
        },
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .page-wrapper.contact {
        background-color: $blue-lighter;
        @include font-med;

        h1 {
            padding: $defaultPadding*3 0;

            @include tablet {
                padding: $defaultPadding*3 0 $defaultPadding*5;
            }
        }

        .contact-content {
            @include grid-12;
            gap: $defaultPadding*2 0;
            grid-gap: $defaultPadding*2 0;

            @include tablet {
                gap: $defaultPadding*3 $defaultPadding*2;
                grid-gap: $defaultPadding*3 $defaultPadding*2;
            }

            .title {
                grid-column: 1 / span 1;

                &:not(:first-of-type) {
                    padding-top: $defaultPadding*2;
                }

                h2 {
                    @include font-big;
                }

                @include tablet {
                    grid-column: 1 / span 12;
                }
            }

            .text {
                grid-column: 1 / span 1;

                address {
                    padding: 0;
                    margin: 0;
                    text-decoration: none;
                    text-transform: none;
                    font-style: normal;

                    figure {
                        margin: 0;

                        img {
                            display: inline-flex;
                            width: 100%;

                            @include tablet {
                                width: 50%;
                            }
                        }
                    }
                }

                p:not(:last-child) {
                    padding-bottom: $defaultPadding*1.5;
                }

                @include tablet {
                    grid-column: auto / span 4;
                }
            }

            .contactForm {
              background-color: $blue-lighter;
              grid-column: auto / span 6;
            }

            a {
                color: inherit;
            }

            a[href^="mailto:"], a[href^="tel:"], a[href^="https://api.whatsapp.com"] {
                text-decoration: none;
            }
        }

        .cta-component {
            padding-top: 6rem;
        }
    }
</style>

