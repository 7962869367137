<template>
    <div class="page-wrapper extra-space-top news-overview-page">
        <section class="default-layout news-overview-heading">
            <h1>Nieuws</h1>

            <div class="news-filters">
                <div class="global-news-filters">
                    <span class="news-filter-title">filter op</span>
                    <button :class=" 'Type' === filter ? 'active' : null " @click="triggerFilter('Type', filter.id )">
                        Type
                        <span class="filter-amount" v-if="activeTypeFilters.length > 0" v-text="activeTypeFilters.length"></span>
                    </button>
                    <button :class=" 'Sector' === filter ? 'active' : null " @click="triggerFilter('Sector')">
                        Sector
                        <span class="filter-amount" v-if="activeSectorFilters.length > 0" v-text="activeSectorFilters.length"></span>
                    </button>
                    <button :class=" 'Thema' === filter ? 'active' : null " @click="triggerFilter('Thema')">
                        Thema
                        <span class="filter-amount" v-if="activeThemeFilters.length > 0" v-text="activeThemeFilters.length"></span>
                    </button>
                </div>
                <div class="specific-news-filters" v-if="typeFilters && filter === 'Type' ">
                    <button :class="activeTypeFilters.includes(filter.id) ? 'active' : null" v-for="(filter, index) in typeFilters" :key="index" v-text="filter.title" @click="triggerFilter('Type', filter)"></button>
                </div>
                <div class="specific-news-filters" v-if="sectorFilters && filter === 'Sector' ">
                    <button :class="activeSectorFilters.includes(filter.id) ? 'active' : null" v-for="(filter, index) in sectorFilters" :key="index" v-text="filter.title" @click="triggerFilter('Sector', filter)"></button>
                </div>
                <div class="specific-news-filters" v-if="themeFilters && filter === 'Thema' ">
                    <button :class="activeThemeFilters.includes(filter.id) ? 'active' : null" v-for="(filter, index) in themeFilters" :key="index" v-text="filter.title" @click="triggerFilter('Thema', filter)"></button>
                </div>
            </div>
        </section>
        <NewsOverview v-if="!loading" :content="news" :full="true" :pages="amountOfPages" :currentPage="currentPage" />
    </div>
</template>

<script>
    import DefaultPage from '@/js/mixins/DefaultPage.vue'
    import NewsOverview from '@/js/components/contentBlocks/NewsOverview.vue'
    import ButtonArrow from '../../svg/buttonArrow.vue'

    export default {
        mixins: [DefaultPage],
        components: {
                ButtonArrow,
                NewsOverview,
                DefaultPage
        },
        data: () => ({
            filter: "Type",
            filters: {},
            typeFilters: null,
            currentPage: 1,
            amountOfPages: 1,
            themeFilters: null,
            sectorFilters: null,
            activeTypeFilters: [],
            activeThemeFilters: [],
            activeSectorFilters: [],
            news: [],
            loading: true,
            urlParams: "",
        }),
        computed: {
        },
        props: {
            content: Object,
        },
        methods: {
            triggerFilter(filter, subFilter) {
                if (filter && !subFilter) {
                    this.filter = filter;
                } else {
                    switch (filter) {
                        case 'Type':
                            this.activeTypeFilters.includes(subFilter.id)
                                ? this.activeTypeFilters = this.activeTypeFilters.filter( item => item !== subFilter.id)
                                : this.activeTypeFilters.push(subFilter.id);
                            break;
                        case 'Thema':
                            this.activeThemeFilters.includes(subFilter.id)
                                ? this.activeThemeFilters = this.activeThemeFilters.filter( item => item !== subFilter.id)
                                : this.activeThemeFilters.push(subFilter.id);
                            break;
                        case 'Sector':
                            this.activeSectorFilters.includes(subFilter.id)
                                ? this.activeSectorFilters = this.activeSectorFilters.filter( item => item !== subFilter.id)
                                : this.activeSectorFilters.push(subFilter.id);
                            break;
                        default:
                            break;
                    }
                }

                this.updateUrl();
                this.fetch();
            },
            updateUrl() {
                const params = new URLSearchParams();

                const filters = {};

                if (this.activeTypeFilters.length) {
                    filters.typeFilters = this.activeTypeFilters;
                }

                if (this.activeThemeFilters.length) {
                    filters.themeFilters = this.activeThemeFilters;
                }

                if (this.activeSectorFilters.length) {
                    filters.sectorFilters = this.activeSectorFilters;
                }

                if (Object.keys(filters).length) {
                    params.set("filters", btoa(JSON.stringify(filters)));
                }

                if (this.currentPage !== 1) {
                    params.set("page", this.currentPage);
                }

                this.urlParams = params.toString();

                if (params.toString() !== "") {
                    window.history.replaceState(null, "",  "?" + params.toString());
                } else {
                    window.history.replaceState({}, "", location.pathname);
                }
            },
            fetch() {
                this.$http.get("/api/v1/news.json?" + this.urlParams)
                    .then(response => {
                        this.news = response.data.data;
                        this.filters = response.data.meta.newsFilters;
                        this.typeFilters = this.filters.types.options;
                        this.themeFilters = this.filters.themes.options;
                        this.sectorFilters = this.filters.sectors.options;
                        this.amountOfPages = response.data.meta.pagination.total_pages;
                        this.currentPage = response.data.meta.pagination.current_page;
                        this.loading = false;
                        this.$forceUpdate();
                    });
                },
            },
            mounted() {
                const params = new URLSearchParams(window.location.search);

                if (params.has("filters")) {
                    const filters = JSON.parse(atob(params.get("filters")));

                    if("typeFilters" in filters) {
                        this.activeTypeFilters = filters.typeFilters;
                    }

                    if("themeFilters" in filters) {
                        this.activeThemeFilters = filters.themeFilters;
                    }

                    if("sectorFilters" in filters) {
                        this.activeSectorFilters = filters.sectorFilters;
                    }

                    this.currentPage = params.has("page")
                        ? parseInt(params.get("page"), 10)
                        : 1;

                    this.urlParams = params.toString();
                }
                this.updateUrl();
                this.fetch();
            },
            watch: {
                '$store.state.newsPagination': function() {
                    this.currentPage = this.$store.state.newsPagination;
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    this.updateUrl();
                    this.fetch();
                }
            }
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .news-overview-heading {
        margin-bottom: 40px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
    }

    .news-filters {
        margin-top: 20px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .global-news-filters {
        display: flex;

        .news-filter-title {
            margin-right: 8px;
        }

        button {
            display: flex;
            margin: 0 10px;
            background: none;
            border: none;
            color: $blue-light;
            cursor: pointer;
            padding: unset;
            font-weight: 600;

            .filter-amount {
                display: flex;
                align-items: center;
                justify-content: center;
                width: fit-content;
                font-size: 10px;
                height: 14px;
                width: 14px;
                margin-top: 2px;
                margin-left: 4px;
                padding-top: 2px;
                border-radius: 100vw;
                color: white;
                background: $blue;
            }

            &.active {
                padding-bottom: 4px;
                border-bottom: 2px solid $blue;
                color: $blue;
            }

            &:hover {
                color: $blue;
            }
        }
    }

    .specific-news-filters {
        margin-top: 14px;

        button {
            margin-top: 10px;
            margin-right: 10px;
            cursor: pointer;
            line-height: 1;
            padding: 7px 12px 5px 12px;
            color: $blue;
            background: white;
            border: unset;
            border-radius: 100vw;

            &.active {
                background-color: $blue;
                color: white;
            }

            &:hover {
                background-color: $blue;
                color: white;
            }
        }
    }

    .news-overview-page {
        background-color: $blue-lighter;

        .news-overview-component {
            margin-top: 0;
            margin-bottom: 0;
            padding-bottom: $defaultPadding*4;
        }
    }

    @include tablet {
        .news-overview-heading {
            flex-direction: row;
            align-items: center;
        }

        .news-filters {
            margin-top: unset;
            align-items: flex-end;
        }

        .specific-news-filters {
            button {
                margin-left: 10px;
                margin-right: unset;
                margin-top: unset;
            }
        }
    }
</style>

