<template>
    <section class="default-layout news-overview-component">
        <h4 v-if="!full" v-text="title"></h4>
        <div class="projects-wrapper">
            <ol>
                <li v-for="(news, index) in content" class="project" :key="'item_'+index">
                    <router-link :to="{ path: news.uri }" v-if="!full ? index < 3 : true">
                        <div class="thumbnail-wrapper">
                            <div class="content">
                                <img :srcset="news.image.srcset" :src="news.image.src" data-sizes="auto" class="lazyload">
                            </div>
                        </div>
                        <h3 class="title">{{ news.title }}</h3>
                        <p class="teaser">{{ news.newsTeaser }}</p>
                    </router-link>
                </li>
                <li v-if="content === null  || Object.keys(content).length === 0">
                    Geen nieuwsartikelen beschikbaar.
                </li>
            </ol>
        </div>
        <div v-if="!full" class="button-wrapper">
            <router-link :to="{ path: '/nieuws' }" class="button circle-arrow">
                Al ons nieuws
                <span class="icon">
                    <ButtonArrowSVG/>
                </span>
            </router-link>
        </div>
        <pagination :pages="pages" :current-page="currentPage" v-if="pages > 1 && activePage && full"></pagination>
    </section>
</template>

<script>
    import ButtonArrowSVG from '@/js/svg/buttonArrow.vue';
    import Pagination from '@/js/components/NewsOverview/Pagination.vue';

    export default {
        components: {
            ButtonArrowSVG,
            Pagination
        },
        props: {
            index: {
                type: Number
            },
            title: {
                type: String,
                required: false,
            },
            content: {
                type: Object | Array,
                required: true
            },
            nextComponentType: {
                type: String,
                required: false
            },
            full: {
                type: Boolean,
                required: false
            },
            pages: {
                type: Number,
                required: false,
            },
            currentPage: {
                type: Number,
                required: false,
            }
        },
        emits: ['changePage'],
        data() {
            return {
              pageList: [],
              activePage: this.currentPage,
              tempPageList: [],
            };
        },
        methods: {
        },
        mounted() {
            if (this.pages) {
                const arrayPages = [];

                for (let index = 1; index <= this.pages; index++) {
                    arrayPages.push(index);
                }

                this.pageList = arrayPages;
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/helper.scss';

    .news-overview-component {
        margin-top: $defaultPadding*5;
        margin-bottom: $defaultPadding*5;

        h4 {
            @include font-big;
            padding-bottom: 0.5em;
        }

        .button-wrapper {
            display: flex;
            justify-content: flex-end;
            padding-top: $defaultPadding*1.5;
        }

        @include tablet {
            .button-wrapper {
                margin-top: -#{$defaultPadding*1.5};
            }
        }

        ol {
            list-style: none;
            display: grid;
            grid-template-columns: 1fr;
            gap: $defaultPadding*2;

            li.project {
                @include font-big;
                padding-bottom: $defaultPadding;
                transition: all .25s cubic-bezier(0.65, 0, 0.35, 1);

                h3 {
                    @include font-big;
                }

                a {
                    color: inherit;
                    text-decoration: none;

                    &:hover {
                        .teaser {
                            color: $blue;
                        }
                    }
                }

                .thumbnail-wrapper {
                    margin-bottom: $defaultPadding;
                    overflow: hidden;
                    @include aspect-ratio(650, 380);
                    background-color: $grey;

                    img {
                        @include lazyloaded;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        transition: transform .9s cubic-bezier(0.65, 0, 0.35, 1);
                    }
                }

                .title {
                    font-size: 26px;
                }

                .teaser {
                    margin-top: 8px;
                    font-size: 20px;
                    color: $blue-light;
                    transition: color .25s cubic-bezier(0.65, 0, 0.35, 1);
                }

                &:hover {
                    img {
                        transform: scale(1.018);
                    }
                }
            }

            @include tablet {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        .pagination {
            display: flex;
            width: 100%;
            align-content: center;
            justify-content: flex-end;

            .reverse {
                    transform: rotate(180deg);
                }

            .page-number {
                cursor: pointer;
                font-size: 20px;
                color: $blue;
                margin-right: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100vw;
                width: 44px;
                height: 44px;

                &.active {
                    background-color: $blue;
                    color: white;
                }

                &:hover {
                    background-color: $blue;
                    color: white;
                }

                &.none {
                    display: none;
                }

            }

        }
    }
</style>