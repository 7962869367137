<template>
    <div class="page-wrapper contact" ref="form">
        <section class="text-component">
            <div class="default-layout content" id="hs-form"></div>
        </section>
    </div>
</template>
<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'
    
    export default {
        mixins: [DefaultPage],
        data: function() {
            return {
              
            }
        },
        computed: {
            
        },
        methods: {
            
        },
        mounted() {
            let hsScript = document.createElement('script')
            hsScript.id = 'hs-script'
            hsScript.setAttribute('src', '//js.hsforms.net/forms/v2.js')
            document.head.appendChild(hsScript)

            hsScript.onload = () => {
                hbspt.forms.create({
                    target: '#hs-form',
                    region: "na1",
                    portalId: "2022461",
                    formId: "6677b19a-1d0d-412d-bb47-66c6e4cf196c"
                })
            }

            let hsLoaderScript = document.createElement('script')
            hsLoaderScript.id = 'hs-script-loader'
            hsLoaderScript.setAttribute('src', '//js.hs-scripts.com/2022461.js')
            document.head.appendChild(hsLoaderScript)
        },
        created() {

        },
        destroyed() {
           let hsScript = document.getElementById('hs-script')
           document.head.removeChild(hsScript)

           let hsLoaderScript = document.getElementById('hs-script-loader')
           document.head.removeChild(hsLoaderScript)
        }

    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .page-wrapper.contact {
        background-color: $blue-lighter;
        @include font-med;
        
        h1 {
            padding: $defaultPadding*3 0;

            @include tablet {
                padding: $defaultPadding*3 0 $defaultPadding*5;
            }
        }

        .contact-content {
            @include grid-12;
            gap: $defaultPadding*2 0;
            grid-gap: $defaultPadding*2 0;

            @include tablet {
                gap: $defaultPadding*3 $defaultPadding*2;
                grid-gap: $defaultPadding*3 $defaultPadding*2;
            }

            .title {
                grid-column: 1 / span 1;

                &:not(:first-of-type) {
                    padding-top: $defaultPadding*2;
                }

                h2 {
                    @include font-big;
                }

                @include tablet {
                    grid-column: 1 / span 12;
                }
            }

            .text {
                grid-column: 1 / span 1;

                address {
                    padding: 0;
                    margin: 0;
                    text-decoration: none;
                    text-transform: none;
                    font-style: normal;

                    figure {
                        margin: 0;

                        img {
                            display: inline-flex;
                            width: 100%;

                            @include tablet {
                                width: 50%;
                            }
                        }
                    }
                }

                p:not(:last-child) {
                    padding-bottom: $defaultPadding*1.5;
                }

                @include tablet {
                    grid-column: auto / span 4;
                }
            }

            a {
                color: inherit;
            }

            a[href^="mailto:"], a[href^="tel:"], a[href^="https://api.whatsapp.com"] {
                text-decoration: none;
            }
        }

        .cta-component {
            padding-top: 6rem;
        }
    }    
</style>

