<template>
    <div class="page-wrapper expertise" :class="pageClass">
        <span class="background" :class="$store.state.menuBgClass">
            <component 
                v-for="(component, index) in content.contentBlocks" 
                    :is="component.name"
                    :content="component.data" 
                    :index="index"
                    :nextComponentType="content.contentBlocks[index+1] ? content.contentBlocks[index+1].name : null"
                    :key="component.name+'_'+index"
            />
        </span>
        <CtaComponent v-if="content.cta" :content="content.cta"/>
        <span v-if="content.parentInfo" class="background-color blue-lighter parent-info">
            <TextComponent :content="content.parentInfo"/>
            <div class="default-layout grid-12 button-wrapper">
                <router-link :to="{ path: content.parentInfo.uri }" class="button circle-arrow">
                    Ga terug naar {{content.parentInfo.title}}
                    <span class="icon">
                        <buttonArrowSVG/>
                    </span>
                </router-link>
            </div>
        </span>
    </div>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'
    import buttonArrowSVG from '@/js/svg/buttonArrow.vue';
    
    export default {
        mixins: [DefaultPage],
        components: {
            buttonArrowSVG
        },
        data: function() {
            return {
              
            }
        },
        computed: {

        },
        methods: {
          
        },
        mounted() {
            
        },
        created() {
            this.menuClass = 'blue-lighter';
        }
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .page-wrapper.expertise {
        .text-component:first-child {
            background: $blue-lighter;
            color: $blue;
        }

        .parent-info {
            padding: $defaultPadding*2 0 $defaultPadding*4;

            .text-component .content{
                padding-bottom: $defaultPadding*1.5;
            }

            .button-wrapper {
                display: grid;
                a {
                    grid-column: 1/1;

                    @include tablet {
                        grid-column: 7/ span 6;
                    }
                }
            }
        }
    }
</style>

