<template>
    <div class="parts-component default-layout grid-12">
        <div class="text">
            <h3>{{ content.title }}</h3>
            <p v-html="content.body"></p>
            <div class="button-wrapper">
                <router-link :to="{ path: content.buttonUri }" class="button circle-arrow">
                    <span class="icon left">
                        <buttonArrowSVG/>
                    </span>
                    {{ content.buttonTitle }}
                </router-link>
            </div>
        </div>
        <div class="image-wrapper">
            <img :data-srcSet="content.image.srcset" data-sizes="auto" class="lazyload">
        </div>
    </div>
</template>

<script>
    import Intersect from 'vue-intersect';
    import buttonArrowSVG from '@/js/svg/buttonArrow.vue'
    
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        components: {
            Intersect,
            buttonArrowSVG
        },
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .parts-component {
        min-height: 630px;
        align-content: center;

        .text {
            grid-column-end: span 1;
            display: flex;
            flex-flow: column;
            justify-content: center;

            padding: $defaultPadding*2 0;

            h3 {
                @include font-big;
                padding-bottom: 0.5em;
            }

            .button-wrapper {
                padding-top: $defaultPadding*1.25;
            }

            @include tablet {
                padding: $defaultPadding*3 0;
            }

            @include tablet {
                grid-column-end: span 4;
            }
        }

        .image-wrapper {
            grid-column: 1/1;
            overflow: hidden;
            padding: $defaultPadding*1.5 0;

            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }

            @include tablet {
                order: initial;
                grid-column: 6/ span 7;
            }
        }
    } 
</style>