<template>
    <div class="page-wrapper services" :class="pageClass">
        <component 
            v-for="(component, index) in content.contentBlocks" 
                :is="component.name"
                :content="component.data" 
                :index="index"
                :nextComponentType="content.contentBlocks[index+1] ? content.contentBlocks[index+1].name : null"
                :key="component.name+'_'+index"
        />
    </div>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'
    
    export default {
        mixins: [DefaultPage],
        data: function() {
            return {
              
            }
        },
        computed: {

        },
        methods: {
          
        },
        mounted() {
            
        },
        created() {
            this.menuClass = 'blue';
        }
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .page-wrapper.services {
        .text-component:first-child {
            background: $blue;
            color: $white;
        }

    }
</style>

