<template>
    <div id="loader" :class="{'show': pageLoading}">
        
    </div>
</template>

<script>
    export default {
        computed: {
            showLoader() {
                return pageLoading && this.$store.state.initializing;
            },
            pageLoading: {
                get () {
                    return this.$store.state.pageLoading;
                },
                set (status) {
                    return this.$store.commit('setPageLoading', status);
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    @import '@/scss/helper.scss';
    
    #loader {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($white, 0);
        pointer-events: none;
        z-index: 6;
        transition: background-color 0.5s cubic-bezier(0.65, 0, 0.35, 1);

        &.show {
            background-color: rgba($white, 1);
            pointer-events: all;
        }
    }
</style>
