<template>
    <article class="perdok-circle-wrapper default-layout">
        <div class="intro">
            <h2>{{ perdokCircle.title }}</h2>
            <p>{{ perdokCircle.body }}</p>
        </div>
        <div class="perdok-circle">
            <div class="svg-container">
                <div class="svg-wrapper">
                    <perdokCircleSvg :class="{'pauze': pauze}" @classChange="activeClassname = $event"/>
                </div>
            </div>
            <ul class="links">
                <li>
                    <h3>{{ perdokCircle.clients.title }}</h3>
                    <p>{{ perdokCircle.clients.body }}</p>
                    <ul>
                        <li v-for="(link, index) in perdokCircle.clients.links"
                            :key="'clientsLink_'+index"
                        >
                            <router-link
                                v-if="!link.external"
                                :to="{ path: link.uri }"
                                @mouseover.native="onMouseOver(link.svgClass)"
                                @mouseout.native="onMouseOver(link.svgClass, true)"
                                :class="{'active': activeClassname == link.svgClass}"
                            >
                                {{ link.title }} <buttonArrowSVG/>
                            </router-link>
                            <a v-else :href="link.uri" target="_blank" ref="noopener noreferrer">
                                {{ link.title }} <buttonArrowSVG/>
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <h3>{{ perdokCircle.services.title }}</h3>
                    <p>{{ perdokCircle.services.body }}</p>
                    <ul>
                        <li v-for="(link, index) in perdokCircle.services.links"
                            :key="'servicesLink_'+index"
                        >
                            <router-link
                                :to="{ path: link.uri }"
                                @mouseover.native="onMouseOver(link.svgClass)"
                                @mouseout.native="onMouseOver(link.svgClass, true)"
                                :class="{'active': activeClassname == link.svgClass}"
                            >
                                {{ link.title }} <buttonArrowSVG/>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <h3>{{ perdokCircle.extraServices.title }}</h3>
                    <p>{{ perdokCircle.extraServices.body }}</p>
                    <ul>
                        <li v-for="(link, index) in perdokCircle.extraServices.links"
                            :key="'extraServicesLink_'+index"
                        >
                            <router-link
                                v-if="link.anchor"
                                :to="{ path: link.uri+'#'+link.anchor }"
                                @mouseover.native="onMouseOver(link.svgClass)"
                                @mouseout.native="onMouseOver(link.svgClass, true)"
                                :class="{'active': activeClassname == link.svgClass}"
                                @click.native="handleAnchor(link.anchor)"
                            >
                                {{ link.title }} <buttonArrowSVG/>
                            </router-link>
                            <router-link
                                v-else
                                :to="{ path: link.uri }"
                                @mouseover.native="onMouseOver(link.svgClass)"
                                @mouseout.native="onMouseOver(link.svgClass, true)"
                                :class="{'active': activeClassname == link.svgClass}"
                            >
                                {{ link.title }} <buttonArrowSVG/>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </article>
</template>

<script>
    import buttonArrowSVG from '@/js/svg/buttonArrow.vue'
    import perdokCircleSvg from '@/js/svg/perdokCircle.vue'

    export default {
        components: {
            buttonArrowSVG,
            perdokCircleSvg
        },
        data() {
            return {
                pauze: false,
                activeClassname: null
            }
        },
        computed: {
            perdokCircle() {
                return this.$store.state.websiteData.perdokCircle;
            }
        },
        methods: {
            onMouseOver(classname, out) {
                let perdokCirkelSVG = this.$el.querySelector('.svg-wrapper svg');
                let activeGroup = perdokCirkelSVG.querySelector(`.${classname}`);

                if(!out) {
                    activeGroup.classList.add('active');
                    this.pauze = true;
                } else {
                    activeGroup.classList.remove('active');
                    this.pauze = false;
                }
            },
            handleAnchor(anchor) {
                console.log('test');
                const scrollToEl = document.querySelector('[data-anchor="'+anchor+'"]');
                if(scrollToEl) {
                    this.ScrollIntoView(scrollToEl,{
                        align: {
                            topOffset: -400
                        },
                    });
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    @import '@/scss/helper.scss';

    .perdok-circle-wrapper {
        @include grid-12;

        .intro {
            grid-column: 3 / span 8;
            text-align: center;
            padding: $defaultPadding*4.5 0;

            h2 {
                @include font-40;
            }

            p {
                @include font-med;
            }
        }

        .perdok-circle {
            @include grid-12;
            grid-column-end: span 12;

            h3 {
                @include font-med;
            }

            .svg-container {
                grid-column: 1 / span 7;
                padding-left: 2%;
                padding-bottom: $defaultPadding*3;
                display: flex;
                align-items: center;

                .svg-wrapper {
                    flex: 1;
                    transform: skew(15deg, 0deg) rotate(-8deg) rotateX(-20deg);

                    svg {
                        path, circle {
                            transition: fill .25s cubic-bezier(0.65, 0, 0.35, 1);
                        }

                        .cirkel-2 {
                            transform-origin: center;
                            animation: perdokCircleSpin 40s linear infinite;
                        }

                        .cirkel-3 {
                            transform-origin: center;
                            animation: perdokCircleSpin 80s linear infinite;
                            animation-direction: reverse;
                        }

                        .achtergrond-1 {
                            transform-origin: center;
                            animation: perdokCircleSpin 80s linear infinite;
                        }

                        .achtergrond-2 {
                            transform-origin: center;
                            animation: perdokCircleSpin 200s linear infinite;
                        }
                    }
                }

                @include tablet {
                    padding-left: 7%;
                    padding-bottom: $defaultPadding*2;

                    .svg-wrapper svg {
                        .cirkel-1, .components, .engineering, .service, .cirkel-3 {
                            &:hover {
                                fill: #2FB152 !important;

                                > path, > circle {
                                    fill: #2FB152 !important;
                                }
                            }
                        }

                        .active {
                            fill: #2FB152 !important;

                            > path, > circle {
                                fill: #2FB152 !important;
                            }
                        }

                        &.pauze, &:hover {
                            .cirkel-2, .cirkel-3, .achtergrond-1, .achtergrond-2 {
                                animation-play-state: paused;
                            }
                        }
                    }
                }
            }

            .links {
                grid-column: span 1;
                list-style: none;

                p {
                    color: $blue-light;
                }

                ul {
                    list-style: none;
                    padding: $defaultPadding*1.2 0 $defaultPadding*2.2;

                    @include grid-4;
                    gap: 0 !important;
                    grid-gap: 0 !important;

                    li {
                        grid-column-end: span 1;

                        border-bottom: 1px solid $blue-light;

                        &:first-child {
                            border-top: 1px solid $blue-light;
                        }

                        a {
                            position: relative;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            color: $blue-light;
                            text-decoration: none;
                            padding: $defaultPadding/1.5 1.5em $defaultPadding/2 0;
                            transition: color .25s cubic-bezier(0.65, 0, 0.35, 1);

                            svg {
                                position: absolute;
                                top: 50%;
                                right: 0;
                                transform: translate3d(0,-50%,0);
                                transition: transform .25s cubic-bezier(0.65, 0, 0.35, 1);

                                polygon {
                                    fill: $blue-light;
                                    transition: fill .25s cubic-bezier(0.65, 0, 0.35, 1);
                                }
                            }

                            &.active, &:hover {
                                color: $blue;

                                svg {
                                    transform: translate3d(-0.25em,-50%,0);

                                    polygon {
                                        fill: $blue;
                                    }
                                }
                            }
                        }

                        @include tablet {
                            grid-column-end: span 4;
                        }

                        @include desktop {
                            grid-column-end: span 3;
                        }
                    }
                }

                @include tablet {
                    grid-column: 9/ span 4;
                }
            }
        }
    }

    @keyframes perdokCircleSpin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

</style>
