<template>
    <div class="text-with-image-blue-component default-layout">
        <div class="action-wrapper">
            <div class="background">
                <span class="img-wrapper">
                    <img :data-srcSet="content.image.srcset" data-sizes="auto" class="lazyload">
                </span>
                <span class="blue"></span>
                <span class="gradient"></span>
            </div>
            <div class="content">
                <div class="text">
                    <h3>{{ content.title }}</h3>
                    <p v-html="content.body"></p>
                    <div class="button-wrapper" v-if="content.buttonUri">
                        <router-link :to="{ path: content.buttonUri }" class="button circle-arrow">
                            <span class="icon left">
                                <buttonArrowSVG color="#fff"/>
                            </span>
                            {{ content.buttonTitle }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import buttonArrowSVG from '@/js/svg/buttonArrow'
    export default {
        components: {
            buttonArrowSVG
        },
        props: {
            index: {
                type: Number
            },
            nextComponentType: {
                type: String,
                required: false
            },
            content: {
                type: Object | Array,
                required: true
            }
        },
        computed: {
            
        },
        data() {
            return {
              
            };
        },
        methods: {
          
        },
        mounted() {
           
        }
    };
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .text-with-image-blue-component {
        padding: $defaultPadding 0;

        .action-wrapper {
            display: flex;
            flex-flow: row wrap;
            position: relative;
            background-color: $blue;
            color: $white;

            .content {
                position: relative;
                @include grid-12;
                justify-content: center;
                align-content: center;
                z-index: 1;

                .text {
                    flex: 0 0 100%;
                    padding: $defaultPadding*2.5 $defaultPadding;
                    grid-column: 1/1;

                    h3 {
                        @include font-big;
                        padding-bottom: 0.5em;
                    }

                    .button-wrapper {
                        padding-top: $defaultPadding*1.25;
                    }

                    @include tablet {
                        padding: $defaultPadding*3 0;
                        margin-left: -#{$defaultPadding*2};
                        grid-column: 2/span 4;
                    }
                }
            }

            .background {
                flex: 0 0 100%;
                min-height: 280px;
                height: 100%;
                position: relative;
                @include grid-12;
                grid-gap: 0 !important;
                gap: 0 !important;
                z-index: 0;
                overflow: hidden;

                @include tablet {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                }

                .img-wrapper {
                    @include grid-12;
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    overflow: hidden;


                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;

                        @include tablet {
                            grid-column: 5/span 8;
                        }
                    }
                }

                .gradient, .blue {
                    z-index: 1;
                    height: 100%;

                    @include tablet {
                        height: 300%;
                    }
                }

                .blue {
                    display: none;
                    background-color: $blue;

                    @include tablet {
                        display: block;
                        grid-column-end: span 4;
                    }
                }

                .gradient {
                    background: linear-gradient(180deg, rgba(0,30,90,0) 0%, #001E5A 100%);

                    @include tablet {
                        grid-column-end: span 8;
                        background: linear-gradient(270deg, rgba(0,30,90,0) 0%, #001E5A 100%);

                    }
                }
            }

            @include tablet {
                min-height: 600px;
            }
        }
    } 
</style>