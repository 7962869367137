<template>
    <div id="curtains" :class="{'show': show}"></div>
</template>

<script>
    export default {
        computed: {
            show: { 
                get() {
                    return this.$store.state.showCurtains;
                },
                set(state) {
                    this.$store.state.showCurtains = state;
                }
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    @import '@/scss/helper.scss';
    
    #curtains {
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($blue, 0);
        transition: background-color 0.25s cubic-bezier(0.65, 0, 0.35, 1);
        pointer-events: none;

        &.show {
            background-color: rgba($blue, 0.2);
            pointer-events: all;
        }
    }
</style>
