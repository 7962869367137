<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 949.99 949.75">
        <g class="achtergrond-2" data-name="Achtergrond 2">
            <path d="M1000,625V525.13c-209.42,0-386.06,128.34-450.78,327.51l94.31,30.64C692.6,733.32,833.65,625,1000,625Z" transform="translate(-525.01 -525.13)" style="fill:none"/>
            <path d="M1000,1375a373.31,373.31,0,0,1-220.86-71.91l-57.74,79.46c169.43,123.1,387.77,123.1,557.2,0l-57.74-79.46A373.31,373.31,0,0,1,1000,1375Z" transform="translate(-525.01 -525.13)" style="fill:#b4c5e0"/>
            <path d="M1450.78,852.64l-94.31,30.64A374.39,374.39,0,0,1,1375,1000c0,124.53-60.71,234.89-154.14,303.09l57.74,79.46C1448,1259.46,1515.49,1051.8,1450.78,852.64Z" transform="translate(-525.01 -525.13)" style="fill:none"/>
            <path d="M1000,625c166.35,0,307.4,108.32,356.47,258.28l94.31-30.64C1386.06,653.47,1209.41,525.13,1000,525.13V625Z" transform="translate(-525.01 -525.13)" style="fill:#b4c5e0"/>
            <path d="M669.49,1177.32l-87.55,48.25c33,60.07,80,113.75,139.46,157l57.74-79.46A376.79,376.79,0,0,1,669.49,1177.32Z" transform="translate(-525.01 -525.13)" style="fill:none"/>
            <path d="M625,1000a374.39,374.39,0,0,1,18.53-116.72l-94.31-30.64c-42,129.21-28.31,262,32.72,372.93l87.55-48.25A373.42,373.42,0,0,1,625,1000Z" transform="translate(-525.01 -525.13)" style="fill:#b4c5e0"/>
        </g>
        <g class="achtergrond-1" data-name="Achtergrond 1">
            <path d="M1000,1174.47v100.46c121.24,0,223.51-74.31,261-189.61l-95.62-31.07A175.05,175.05,0,0,1,1000,1174.47Z" transform="translate(-525.01 -525.13)" style="fill:none"/>
            <path d="M999.11,1174.48A175.07,175.07,0,0,1,833,1054.77l-94,30.55c37.47,115.3,139.74,189.61,261,189.61V1174.47Z" transform="translate(-525.01 -525.13)" style="fill:#b4c5e0"/>
            <path d="M739,1085.32l94-30.55a175.19,175.19,0,0,1,63.31-196.93l-57.63-79.32C740.62,849.78,701.56,970,739,1085.32Z" transform="translate(-525.01 -525.13)" style="fill:none"/>
            <path d="M999.11,824.48a174.25,174.25,0,0,1,103.94,34.21l58.24-80.17c-98.08-71.26-224.5-71.26-322.58,0l57.63,79.32A174.24,174.24,0,0,1,999.11,824.48Z" transform="translate(-525.01 -525.13)" style="fill:#b4c5e0"/>
            <path d="M1174.11,999.48a174.92,174.92,0,0,1-8.75,54.77l95.62,31.07c37.46-115.31-1.6-235.54-99.69-306.8l-58.24,80.17A174.75,174.75,0,0,1,1174.11,999.48Z" transform="translate(-525.01 -525.13)" style="fill:none"/>
        </g>
        <g class="cirkel-3" data-name="Cirkel 3" @mouseover="activeClassname = 'cirkel-3'" @mouseleave="activeClassname = null">
            <path d="M1000,750V650.09c-154.31,0-284.47,94.57-332.15,241.33l94.53,30.71C795.12,822.19,889.12,750,1000,750Z" transform="translate(-525.01 -525.13)" style="fill:#fff;stroke:#001f5e;stroke-miterlimit:10;stroke-width:2px"/>
            <path d="M750,1000a250,250,0,0,1,12.38-77.87l-94.53-30.71c-47.68,146.75,2,299.76,126.87,390.46l58-79.86A249.62,249.62,0,0,1,750,1000Z" transform="translate(-525.01 -525.13)" style="fill:#fff;stroke:#001f5e;stroke-miterlimit:10;stroke-width:2px"/>
            <path d="M1000,1250a248.8,248.8,0,0,1-147.26-48l-58,79.86c124.84,90.7,285.72,90.7,410.56,0l-58-79.86A248.8,248.8,0,0,1,1000,1250Z" transform="translate(-525.01 -525.13)" style="fill:#fff;stroke:#001f5e;stroke-miterlimit:10;stroke-width:2px"/>
            <path d="M1332.15,891.42l-94.53,30.71A250.25,250.25,0,0,1,1147.26,1202l58,79.86C1330.12,1191.18,1379.83,1038.17,1332.15,891.42Z" transform="translate(-525.01 -525.13)" style="fill:#fff;stroke:#001f5e;stroke-miterlimit:10;stroke-width:2px"/>
            <path d="M1237.62,922.13l94.53-30.71C1284.47,744.66,1154.31,650.09,1000,650.09V750C1110.88,750,1204.88,822.19,1237.62,922.13Z" transform="translate(-525.01 -525.13)" style="fill:#fff;stroke:#001f5e;stroke-miterlimit:10;stroke-width:2px"/>
            <g>
                <path d="M1132.5,741.63l8.5-16.25-5.19-2.71,1-2,12.65,6.62-1,2-5.19-2.71-8.49,16.25Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1150.31,734l-1.77,3.1,0,0c1.65-1.44,3.73-2.47,5.86-1.25l.73.49-1.12,1.95-.41-.24a4.3,4.3,0,0,0-4.61.14,6,6,0,0,0-2.27,2.46l-3.81,6.67-2-1.16,7.57-13.23Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1162.66,748.23l.35-.56c1.43-2.26,1-3.74-1.3-5.19-1.63-1-3.5-1.49-5,.06l-1.66-1.47c2.05-2.47,5.42-1.51,7.78,0,1.4.88,2.9,2,3.16,3.76s-.68,3.44-1.61,4.92l-3.15,5a11.31,11.31,0,0,0-1.44,2.89l-2-1.25,1.3-2.56a5.45,5.45,0,0,1-6.2-.18c-2.41-1.52-3.64-4.18-2-6.74a4.7,4.7,0,0,1,4.65-2.08,14.24,14.24,0,0,1,5.42,2.36Zm-1,1.5-1.64-1c-2-1.28-5.41-3-7.09-.36-1,1.6,0,3.13,1.37,4a5,5,0,0,0,6.84-1.79Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1164.64,761l-1.93-1.33,8.64-12.56,1.92,1.33Zm10.26-14.72-2.12-1.46,1.49-2.18,2.13,1.46Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1177.41,754.8l0,0a5.2,5.2,0,0,1,5.72.27,4.78,4.78,0,0,1,2,4.49c-.15,1.5-1.42,3.09-2.32,4.29l-5.05,6.76-1.87-1.41,5.64-7.54a6,6,0,0,0,1.36-2.51,3.11,3.11,0,0,0-1.27-2.67,4,4,0,0,0-3.43-.66,6,6,0,0,0-3.43,2.57l-4.78,6.39-1.87-1.4,9.13-12.21,1.78,1.33Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1183,774.85l-1.82-1.48,9.6-11.84,1.82,1.48Zm11.4-13.87-2-1.62,1.67-2.05,2,1.62Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1196.2,769.67l0,0a5.2,5.2,0,0,1,5.68.71,4.83,4.83,0,0,1,1.68,4.64c-.27,1.48-1.66,3-2.65,4.1l-5.56,6.34-1.76-1.55,6.22-7.08a5.86,5.86,0,0,0,1.54-2.39,3.1,3.1,0,0,0-1.05-2.76,4,4,0,0,0-3.37-.93,6,6,0,0,0-3.62,2.29l-5.27,6-1.75-1.55,10.06-11.45,1.67,1.47Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1218.92,783.74l.4.44-1.29,1.38-.18-.17c-1-1-1.86-1.61-3.14-.64a5.65,5.65,0,0,1-1.57,3.71,5,5,0,0,1-4.06,1.36,6.37,6.37,0,0,1-3.59-1.87,4.27,4.27,0,0,1-.7-.82c-.16-.19-.41-.61-.61-.8a1.64,1.64,0,0,0-2.14-.12,1.32,1.32,0,0,0-.09,1.72,21.86,21.86,0,0,0,2.41,2.69,17,17,0,0,1,2.82,3.51,3.6,3.6,0,0,1-.68,4c-2.7,2.76-6.34-.08-8.44-2.13a13.28,13.28,0,0,1-2.78-3.56,3.67,3.67,0,0,1,.48-4.23,3.1,3.1,0,0,1,3.81-.59,2.75,2.75,0,0,1,.63-2.75c1-1.07,2.13-.93,3.41-.48a4.94,4.94,0,0,1,1.39-4.26c2.31-2.36,5.62-1.76,7.81.38a7.31,7.31,0,0,1,1.75,2.63C1216.35,781.91,1217.44,782.3,1218.92,783.74Zm-16.2,8.3-2.45-2.73a2.31,2.31,0,0,0-2.63.32c-1.46,1.5.6,4,1.72,5.12a10.92,10.92,0,0,0,3.27,2.27,1.83,1.83,0,0,0,2.2-.36c1.09-1.12-.24-2.72-1.06-3.55Zm8.89-5.41a3.32,3.32,0,0,0-.1-4.75,3.27,3.27,0,0,0-4.79,0,3.41,3.41,0,0,0,4.89,4.74Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1287.5,964.78l20.49-2,.66,6.87a7.42,7.42,0,0,1-.9,5.06,6.45,6.45,0,0,1-4.57,2.56,6.2,6.2,0,0,1-4.5-1.38,6.61,6.61,0,0,1-2.15-4.77l-.45-4.59-8.33.8Zm11.12,5.89a4.25,4.25,0,0,0,1.36,3.16,4.25,4.25,0,0,0,6-.89,4,4,0,0,0,.51-2.85l-.43-4.51-7.89.76Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1304.28,982.86l-3.57.18v.06c2.11.61,4.09,1.8,4.22,4.26v.87l-2.25.12,0-.48a4.26,4.26,0,0,0-2.6-3.8,5.87,5.87,0,0,0-3.3-.58l-7.67.4-.12-2.34,15.22-.79Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1297.1,990.3c4.23-.07,7.93,2.27,8,6.86s-3.79,6.75-7.81,6.82c-4.26.07-8-2.33-8.13-7S1293.08,990.36,1297.1,990.3Zm-3.73,10.38a8.34,8.34,0,0,0,3.91.69,7.71,7.71,0,0,0,4.16-1,4,4,0,0,0,1.74-3.33,3.83,3.83,0,0,0-2.21-3.41,8.64,8.64,0,0,0-3.82-.69,9.09,9.09,0,0,0-4.16.93,3.88,3.88,0,0,0,.38,6.84Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1289,1009.5a6,6,0,0,1-3.51-.89,4.24,4.24,0,0,1-1.48-3.61,8.66,8.66,0,0,1,.37-2.21l1.89.06a6.45,6.45,0,0,0-.3,1.7c-.07,2.28,1.19,2.55,3.1,2.61l15.51.48-.08,2.34Zm18.2.71.08-2.58,2.64.08-.08,2.58Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1292.5,1026.46a6.66,6.66,0,0,1-4-6.78c.32-4.61,4.13-6.72,8.38-6.43s7.82,3,7.5,7.59c-.32,4.73-4.41,6.49-8.62,6.08l.76-11.05a7.34,7.34,0,0,0-3.49.4,4.39,4.39,0,0,0-2.57,3.73,4.2,4.2,0,0,0,2.65,4.27Zm5.16-2c2.51.21,4.73-1.05,4.92-3.75.17-2.54-2-4.34-4.35-4.59Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1291.63,1041.45c-3-.91-5-3.24-4.6-6.48.55-4.5,4.68-6,8.64-5.49s7.72,2.93,7.17,7.43c-.39,3.28-2.75,5-5.94,5.17l-.13-2.19c2,0,3.84-1,4.1-3.16.38-3.16-2.81-4.45-5.35-4.76s-6.1,0-6.49,3.18c-.27,2.26,1.24,3.56,3.24,4.2Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1284.86,1050.85l.19-2.25c.53-3.29,2.61-4,5.6-3.47l8.71,1.41.43-2.66,1.84.29-.43,2.67,3.73.61-.14,2.35-4-.65-.54,3.35-1.84-.3.55-3.35-9.1-1.47c-1.74-.29-2.74.07-3.05,2l-.14,1.81Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1299.17,1058.27l-2.86-.64,0,.06a5.09,5.09,0,0,1,2.26,5.17,4.33,4.33,0,0,1-4.48,3.4,5.31,5.31,0,0,1,2.47,5.59,4.52,4.52,0,0,1-3,3.28,8.67,8.67,0,0,1-4.4-.25l-8.87-2,.51-2.28,8.87,2a6.58,6.58,0,0,0,3,.25,2.87,2.87,0,0,0,2.1-2.15,3.71,3.71,0,0,0-1-3.24,5.82,5.82,0,0,0-3.51-1.64l-8-1.79.5-2.29,9.43,2.11a5.1,5.1,0,0,0,2.63.06,3,3,0,0,0,2-2.17,3.78,3.78,0,0,0-.86-3,5.39,5.39,0,0,0-3.19-1.66l-8.53-1.91.51-2.28,14.88,3.32Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1285.36,1089l.63.19c2.56.76,3.87-.07,4.64-2.66.55-1.84.49-3.77-1.4-4.77l1-2c2.93,1.31,2.92,4.82,2.12,7.49-.46,1.58-1.18,3.35-2.77,4.06s-3.49.28-5.16-.22l-5.66-1.68a11.35,11.35,0,0,0-3.18-.6l.67-2.24,2.81.55a5.48,5.48,0,0,1-1.5-6c.81-2.73,3-4.64,5.94-3.77a4.67,4.67,0,0,1,3.25,3.91,14.19,14.19,0,0,1-.8,5.86Zm-1.7-.5.56-1.87c.68-2.31,1.44-6-1.58-6.92-1.81-.54-3,.79-3.5,2.4a5,5,0,0,0,3.57,6.1Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1285.51,1098l0,0a5.22,5.22,0,0,1,1.42,5.55,4.81,4.81,0,0,1-3.69,3.27c-1.48.3-3.38-.45-4.79-1l-7.94-2.85.79-2.2,8.87,3.18a6,6,0,0,0,2.8.55,3.09,3.09,0,0,0,2.18-2,4.12,4.12,0,0,0-.38-3.48,6,6,0,0,0-3.46-2.52l-7.52-2.69.79-2.21,14.35,5.15-.75,2.09Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1273.81,1119.82l.61.26c2.46,1,3.85.36,4.9-2.13.75-1.77.91-3.69-.86-4.89l1.18-1.88c2.77,1.62,2.37,5.1,1.28,7.67-.64,1.52-1.54,3.19-3.2,3.73s-3.5-.11-5.1-.79l-5.45-2.3a11.24,11.24,0,0,0-3.08-.94l.91-2.16,2.73.86a5.46,5.46,0,0,1-.83-6.14c1.11-2.63,3.54-4.28,6.33-3.1a4.71,4.71,0,0,1,2.8,4.25,14.37,14.37,0,0,1-1.45,5.74Zm-1.63-.69.76-1.8c.93-2.21,2.1-5.82-.8-7-1.75-.74-3.09.45-3.75,2a5,5,0,0,0,2.88,6.46Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1273.5,1139.56l-.29.52-1.71-.8.1-.21c.61-1.24.94-2.28-.38-3.19a5.65,5.65,0,0,1-4-.32,5,5,0,0,1-2.57-3.42,6.35,6.35,0,0,1,.64-4,4.49,4.49,0,0,1,.56-.94,8.42,8.42,0,0,0,.56-.82,1.64,1.64,0,0,0-.56-2.08,1.32,1.32,0,0,0-1.65.46,19.88,19.88,0,0,0-1.79,3.14,17.17,17.17,0,0,1-2.45,3.78,3.6,3.6,0,0,1-4,.64c-3.48-1.7-1.93-6-.65-8.69a13.13,13.13,0,0,1,2.5-3.76,3.65,3.65,0,0,1,4.16-.88,3.1,3.1,0,0,1,1.77,3.43,2.74,2.74,0,0,1,2.8-.27c1.35.66,1.56,1.73,1.54,3.08a5,5,0,0,1,4.48,0c3,1.44,3.45,4.78,2.11,7.53a7.18,7.18,0,0,1-1.95,2.49C1274.43,1136.54,1274.41,1137.69,1273.5,1139.56Zm-13-12.76,1.82-3.19a2.32,2.32,0,0,0-1.14-2.39c-1.89-.91-3.63,1.84-4.31,3.24a11,11,0,0,0-1.13,3.83,1.83,1.83,0,0,0,1,2c1.4.69,2.5-1.08,3-2.12Zm7.94,6.73a3.41,3.41,0,0,0,3-6.14,3.41,3.41,0,0,0-3,6.14Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1253.84,1147.51a6.65,6.65,0,0,1-.77-7.83c2.24-4,6.59-4.35,10.32-2.28s5.8,6.06,3.59,10c-2.3,4.15-6.75,4-10.4,1.86l5.37-9.69a7.25,7.25,0,0,0-3.32-1.12,4.38,4.38,0,0,0-3.91,2.29,4.21,4.21,0,0,0,.59,5Zm5.52.38c2.19,1.25,4.73,1,6-1.31s.07-4.77-2-6Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1260.23,1158.49l-2.47-1.6,0,0a5.1,5.1,0,0,1,.31,5.64,4.33,4.33,0,0,1-5.38,1.63,5.29,5.29,0,0,1,.37,6.1,4.53,4.53,0,0,1-3.92,2,8.69,8.69,0,0,1-4-1.76l-7.63-4.95,1.27-2,7.63,4.94a6.49,6.49,0,0,0,2.77,1.3,2.89,2.89,0,0,0,2.72-1.28,3.7,3.7,0,0,0,.16-3.4,5.72,5.72,0,0,0-2.71-2.76l-6.9-4.47,1.27-2,8.11,5.25a5.07,5.07,0,0,0,2.44,1,3,3,0,0,0,2.62-1.35,3.76,3.76,0,0,0,.24-3.13,5.34,5.34,0,0,0-2.41-2.67l-7.33-4.75,1.27-2,12.79,8.28Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1231,1181.14a6.67,6.67,0,0,1,.31-7.87c2.78-3.69,7.13-3.39,10.53-.83s4.91,6.8,2.18,10.45-7.24,3-10.55.4l6.64-8.85a7.3,7.3,0,0,0-3.13-1.57,4.39,4.39,0,0,0-4.19,1.74,4.2,4.2,0,0,0-.1,5Zm5.41,1.14c2,1.53,4.55,1.68,6.17-.47s.72-4.71-1.15-6.23Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1233.58,1191l0,0a5.21,5.21,0,0,1-.59,5.7,4.8,4.8,0,0,1-4.59,1.78c-1.49-.23-3-1.59-4.16-2.56l-6.46-5.42,1.51-1.79,7.21,6.06a5.84,5.84,0,0,0,2.43,1.49,3.09,3.09,0,0,0,2.73-1.11,4.08,4.08,0,0,0,.86-3.39,6,6,0,0,0-2.37-3.57l-6.11-5.13,1.5-1.79,11.67,9.8-1.43,1.7Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1210.1,1198.75l1.38-1.78c2.25-2.46,4.36-1.87,6.59.18l6.5,6,1.83-2,1.37,1.26-1.83,2,2.79,2.56-1.4,1.88-3-2.71-2.29,2.49-1.37-1.25,2.29-2.5-6.79-6.23c-1.3-1.2-2.33-1.45-3.63,0l-1.1,1.42Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1110.9,1274.42c-.68-4,.3-7.47,4.29-9.21,5.44-2.38,10,1.89,12,6.54,2.13,4.86,1.77,10.55-3.7,13-3.79,1.66-7.13.07-9.42-3l2-1.39c1.7,2.1,3.79,3.65,6.59,2.42,4.09-1.79,3.24-6.72,1.81-10a13.13,13.13,0,0,0-2.93-4.32,5.14,5.14,0,0,0-5.53-1.18c-2.88,1.26-3.28,4-2.8,6.76Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1108.89,1276.62c1.47,4,.48,8.23-3.83,9.82s-7.62-1.34-9-5.12c-1.47-4-.46-8.37,3.88-10S1107.5,1272.84,1108.89,1276.62Zm-11-.09a8.36,8.36,0,0,0,.64,3.92,7.75,7.75,0,0,0,2.35,3.58,4,4,0,0,0,3.72.55,3.88,3.88,0,0,0,2.49-3.22,10.27,10.27,0,0,0-2.88-7.45,3.88,3.88,0,0,0-6.32,2.62Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1091.94,1287.59l-.06,0a5.2,5.2,0,0,1-3.59,4.46,4.78,4.78,0,0,1-4.82-1c-1.13-1-1.67-3-2.11-4.41l-2.47-8.06,2.23-.69,2.77,9a5.86,5.86,0,0,0,1.23,2.57,3.09,3.09,0,0,0,2.9.56,4.09,4.09,0,0,0,2.56-2.39,6,6,0,0,0-.06-4.28l-2.34-7.63,2.24-.69,4.47,14.58-2.12.65Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1070.59,1302l-2.27.56-4.94-20,2.07-.51.65,2.62h.05a5,5,0,0,1,3.92-4.06,5.43,5.43,0,0,1,5.13,1.39,9.57,9.57,0,0,1,2.68,4.84c.91,3.7.09,8.23-4.19,9.29a5.16,5.16,0,0,1-4.88-1.33Zm-2.77-11.22a5.23,5.23,0,0,0,1.48,2.76,4.24,4.24,0,0,0,3.73.84,3.64,3.64,0,0,0,2.54-2.61,7.77,7.77,0,0,0-.07-4.06c-.6-2.42-2.14-5.9-5.32-5.12a4.23,4.23,0,0,0-2.82,2.58,5,5,0,0,0-.13,3.25Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1057,1283.85l2.29-.47,3.06,14.93-2.29.47Zm3.48,17.6,2.53-.52.53,2.59-2.52.52Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1045.47,1285.83l2.19-.57c3.27-.58,4.59,1.17,5.11,4.16l1.53,8.69,2.65-.47.33,1.83-2.66.47.65,3.72-2.26.64-.7-4-3.33.59-.33-1.83,3.34-.59-1.59-9.07c-.31-1.74-1-2.57-2.86-2.24l-1.75.46Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1040.66,1286.64l2.32-.34,2.21,15.08-2.32.34Zm2.48,17.77,2.55-.37.39,2.61-2.55.37Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1038.1,1294.88c.44,4.21-1.56,8.1-6.12,8.58s-7.07-3.17-7.49-7.17c-.45-4.24,1.6-8.23,6.2-8.71S1037.67,1290.88,1038.1,1294.88Zm-10.68-2.79a8.28,8.28,0,0,0-.33,4,7.73,7.73,0,0,0,1.39,4,4,4,0,0,0,3.47,1.44,3.84,3.84,0,0,0,3.2-2.51,8.59,8.59,0,0,0,.35-3.87,9,9,0,0,0-1.3-4.05,3.88,3.88,0,0,0-6.78,1Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1019,1301.37h-.06a5.21,5.21,0,0,1-4.58,3.45,4.81,4.81,0,0,1-4.43-2.15c-.85-1.25-.89-3.29-1-4.79l-.43-8.42,2.34-.12.48,9.42a6.12,6.12,0,0,0,.56,2.79,3.13,3.13,0,0,0,2.68,1.25,4.13,4.13,0,0,0,3.07-1.69,6,6,0,0,0,1-4.17l-.41-8,2.34-.12.78,15.22-2.22.12Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1002.22,1289.63l2.16,0-.35,20.59-3.84-.06-5.93-16.58L987.92,1310l-3.78-.06.35-20.59,2.58,0-.3,18h.12l6.81-17.86,1.74,0,6.36,18.09h.12Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M980.69,1296.78c-.36,4.21-3.07,7.66-7.64,7.27s-6.33-4.46-6-8.47c.36-4.24,3.13-7.77,7.73-7.38S981,1292.77,980.69,1296.78Zm-10-4.77a8.44,8.44,0,0,0-1.07,3.83,7.65,7.65,0,0,0,.6,4.23,4,4,0,0,0,3.13,2.08,3.87,3.87,0,0,0,3.63-1.86,8.87,8.87,0,0,0,1.07-3.73,9,9,0,0,0-.52-4.23,3.88,3.88,0,0,0-6.84-.32Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M960.69,1299.52h-.06a5.22,5.22,0,0,1-5.15,2.52,4.79,4.79,0,0,1-3.94-3c-.6-1.39-.25-3.4,0-4.88l1.17-8.35,2.32.32-1.31,9.33a5.84,5.84,0,0,0,0,2.85,3.1,3.1,0,0,0,2.39,1.74,4.09,4.09,0,0,0,3.33-1.08,6.09,6.09,0,0,0,1.77-3.9l1.11-7.91,2.31.33-2.12,15.09-2.2-.31Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M942.8,1302.27l2.54.46-.47,2.6-2.54-.46Zm3.33-17.63,2.31.42-2.73,15-2.31-.42Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M934.75,1282.2l2.24.29c3.26.69,3.82,2.8,3.19,5.77l-1.82,8.63,2.64.56-.38,1.82-2.65-.56-.78,3.7-2.34-.25.83-3.93-3.31-.71.38-1.82,3.32.7,1.91-9c.36-1.73,0-2.75-1.82-3.15l-1.8-.22Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M931.59,1289.59c-1.05,4.09-4.29,7-8.74,5.89s-5.5-5.45-4.5-9.34c1.07-4.13,4.38-7.15,8.86-6S932.6,1285.69,931.59,1289.59Zm-9-6.36a8.34,8.34,0,0,0-1.7,3.59,7.7,7.7,0,0,0-.11,4.28,4,4,0,0,0,2.75,2.57,3.86,3.86,0,0,0,3.88-1.23,8.73,8.73,0,0,0,1.68-3.51,9,9,0,0,0,.19-4.25,3.87,3.87,0,0,0-6.69-1.45Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M910.7,1291.66l1-3.41-.06,0c-1.29,1.77-3.08,3.23-5.44,2.51l-.81-.31.66-2.15.46.14a4.27,4.27,0,0,0,4.45-1.15,6,6,0,0,0,1.68-2.91l2.24-7.35,2.24.69-4.45,14.57Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M899.81,1291l2.44.82-.84,2.5-2.45-.82Zm5.84-17,2.22.75L903,1289.22l-2.22-.75Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M895.08,1283.47l-.05,0a5.23,5.23,0,0,1-5.58,1.31,4.79,4.79,0,0,1-3.18-3.76c-.27-1.48.51-3.37,1.05-4.77l3-7.87,2.18.83-3.36,8.8a6,6,0,0,0-.62,2.78,3.15,3.15,0,0,0,2,2.23,4.13,4.13,0,0,0,3.49-.31,6.09,6.09,0,0,0,2.59-3.41l2.84-7.45,2.19.83-5.44,14.24-2.07-.79Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M868.89,1278.19l-.54-.27.74-1.74.22.09c1.26.57,2.31.87,3.18-.49a5.58,5.58,0,0,1,.18-4,5,5,0,0,1,3.33-2.69,6.32,6.32,0,0,1,4,.5,4.68,4.68,0,0,1,1,.53,9.36,9.36,0,0,0,.84.53,1.63,1.63,0,0,0,2.05-.62,1.32,1.32,0,0,0-.51-1.65,21.52,21.52,0,0,0-3.19-1.68,16.6,16.6,0,0,1-3.87-2.31,3.59,3.59,0,0,1-.77-4c1.57-3.54,6-2.14,8.66-.94a13.17,13.17,0,0,1,3.84,2.36,3.66,3.66,0,0,1,1,4.13,3.1,3.1,0,0,1-3.37,1.89,2.75,2.75,0,0,1,.37,2.79c-.61,1.37-1.67,1.62-3,1.64a5,5,0,0,1,.18,4.48c-1.35,3-4.66,3.6-7.46,2.36a7.34,7.34,0,0,1-2.55-1.86C871.93,1279,870.78,1279,868.89,1278.19Zm5.84-5.25a3.32,3.32,0,0,0,1.75,4.42,3.27,3.27,0,0,0,4.49-1.68,3.17,3.17,0,0,0-1.76-4.46A3.21,3.21,0,0,0,874.73,1272.94Zm6.46-8.16,3.24,1.71a2.31,2.31,0,0,0,2.35-1.22c.86-1.92-2-3.57-3.38-4.2a10.76,10.76,0,0,0-3.86-1,1.83,1.83,0,0,0-1.94,1.11c-.63,1.43,1.17,2.46,2.23,3Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M773.78,1181.71l-3.26,5.45,4.91,5.83,5.77-2.46,1.39,1.65-20,8.15-1.91-2.27,11.45-18.37Zm-9.91,15.9,9.6-3.92-4.07-4.84Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M766.48,1179.7c1.46-1.51.63-3.92-.47-5.38-1-1.3-2.68-2.61-4.24-1.44-1.29,1-.66,2.56-.06,3.76l1.14,2.27c1,2.14,1.46,4.49-.7,6.11-2.52,1.89-5.32.36-7-1.87s-2.35-4.84-.55-7.13l1.66,1.46a3.84,3.84,0,0,0,.42,4.56c.83,1.1,2.38,2.26,3.72,1.25s.78-2.31.14-3.51l-1-2a8.4,8.4,0,0,1-1.08-3.18,4.08,4.08,0,0,1,1.67-3.53c2.75-2.08,5.78-.15,7.56,2.23s2.57,5.47.54,8Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M757.27,1167.13c1.54-1.43.83-3.88-.19-5.4-.9-1.34-2.53-2.74-4.15-1.66-1.34.91-.8,2.53-.26,3.76l1,2.32c.94,2.19,1.23,4.56-1,6.07-2.61,1.75-5.33.07-6.88-2.25s-2.1-5-.18-7.14l1.58,1.54a3.82,3.82,0,0,0,.18,4.57c.77,1.15,2.26,2.39,3.65,1.46s.9-2.27.32-3.51l-.91-2.06a8.11,8.11,0,0,1-.9-3.22,4.09,4.09,0,0,1,1.84-3.45c2.87-1.92,5.78.17,7.44,2.63s2.27,5.6.12,8Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M743.32,1144.38a6.66,6.66,0,0,1,7.16,3.28c2.37,4,.44,7.88-3.22,10.07s-8.16,2-10.49-2-.07-7.85,3.62-9.92l5.67,9.51a7.19,7.19,0,0,0,2.64-2.3,4.4,4.4,0,0,0,0-4.54,4.21,4.21,0,0,0-4.62-2Zm-3.1,4.58c-2.18,1.27-3.29,3.57-1.91,5.89s4.09,2.46,6.2,1.3Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M741.92,1132.72l1.23,1.89c1.57,2.94.33,4.73-2.34,6.17L733,1145l1.28,2.38-1.63.88-1.28-2.38-3.33,1.8-1.32-2,3.54-1.91-1.61-3,1.64-.88,1.6,3,8.11-4.37c1.56-.84,2.14-1.73,1.23-3.42l-1-1.51Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M726.06,1135.58l2.67-1.25,0,0a5.09,5.09,0,0,1-4.94-2.73,4.34,4.34,0,0,1,1.48-5.42,5.31,5.31,0,0,1-5.37-2.93,4.54,4.54,0,0,1,.36-4.4,8.52,8.52,0,0,1,3.64-2.49l8.24-3.84,1,2.12-8.24,3.84a6.49,6.49,0,0,0-2.57,1.66,2.87,2.87,0,0,0-.36,3,3.72,3.72,0,0,0,2.79,2,5.81,5.81,0,0,0,3.78-.84l7.45-3.47,1,2.12-8.76,4.08a5.11,5.11,0,0,0-2.12,1.55,3,3,0,0,0-.26,2.93,3.78,3.78,0,0,0,2.53,1.87,5.39,5.39,0,0,0,3.54-.62l7.92-3.69,1,2.12L727,1137.51Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M718.29,1102.76l-.62.23c-2.49,1-3,2.42-2.06,4.94.69,1.79,1.9,3.29,4,2.93l.44,2.17c-3.12.75-5.24-2-6.24-4.64-.59-1.54-1.1-3.37-.28-4.91s2.6-2.35,4.23-3l5.52-2.12a11.28,11.28,0,0,0,2.88-1.45l.84,2.18-2.57,1.27a5.47,5.47,0,0,1,4.85,3.86c1,2.67.42,5.53-2.41,6.62a4.7,4.7,0,0,1-5-1.12,14.2,14.2,0,0,1-2.93-5.14Zm1.65-.64.7,1.83c.86,2.24,2.52,5.65,5.46,4.52,1.77-.68,1.91-2.47,1.31-4a5,5,0,0,0-6.55-2.66Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M712.67,1095.7l0-.06a5.2,5.2,0,0,1-4.51-3.53,4.81,4.81,0,0,1,.94-4.84c1-1.14,2.95-1.7,4.38-2.16l8-2.58.72,2.23-9,2.88a5.87,5.87,0,0,0-2.56,1.26,3.12,3.12,0,0,0-.51,2.91,4.1,4.1,0,0,0,2.42,2.53,6.07,6.07,0,0,0,4.28-.11l7.6-2.44.71,2.22-14.51,4.66-.68-2.11Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M708.68,1071.31l-.64.17c-2.58.68-3.28,2.07-2.59,4.68.48,1.86,1.53,3.48,3.66,3.35l.21,2.21c-3.18.4-5-2.6-5.7-5.3-.41-1.6-.72-3.47.27-4.91s2.85-2,4.53-2.49l5.72-1.49a11.23,11.23,0,0,0,3-1.13l.59,2.26-2.69,1a5.47,5.47,0,0,1,4.4,4.38c.72,2.75-.2,5.54-3.13,6.3a4.68,4.68,0,0,1-4.8-1.66,14.06,14.06,0,0,1-2.35-5.43Zm1.71-.44.49,1.88c.61,2.33,1.88,5.9,4.93,5.1,1.83-.47,2.17-2.24,1.75-3.87a5,5,0,0,0-6.21-3.36Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M696.89,1055.46l-.09-.6,1.85-.41.05.24c.27,1.35.64,2.38,2.24,2.29a5.68,5.68,0,0,1,3.38-2.19,5,5,0,0,1,4.12,1.14,6.36,6.36,0,0,1,1.93,3.56,4.91,4.91,0,0,1,.13,1.08c0,.24,0,.73,0,1a1.64,1.64,0,0,0,1.71,1.3,1.31,1.31,0,0,0,1-1.37,19.76,19.76,0,0,0-.49-3.58,17.18,17.18,0,0,1-.37-4.49,3.61,3.61,0,0,1,2.83-3c3.79-.77,5.21,3.62,5.8,6.5a13.41,13.41,0,0,1,.31,4.5,3.66,3.66,0,0,1-2.77,3.24,3.11,3.11,0,0,1-3.49-1.65,2.74,2.74,0,0,1-2.05,1.93c-1.47.3-2.3-.42-3.1-1.51a5,5,0,0,1-3.54,2.74c-3.23.67-5.65-1.69-6.26-4.68a7.53,7.53,0,0,1,0-3.17C698,1058.42,697.31,1057.48,696.89,1055.46Zm7.67,1.7a3.32,3.32,0,0,0-2.58,4,3.28,3.28,0,0,0,4,2.68,3.41,3.41,0,0,0-1.4-6.67Zm10.39.5.5,3.64a2.32,2.32,0,0,0,2.36,1.2c2.06-.42,1.76-3.67,1.45-5.2a10.72,10.72,0,0,0-1.44-3.72,1.81,1.81,0,0,0-2-.93c-1.53.31-1.32,2.38-1.12,3.54Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M707.64,1037.17a6.66,6.66,0,0,1,5.38,5.75c.69,4.57-2.58,7.45-6.79,8.09s-8.29-1.27-9-5.79c-.7-4.68,2.91-7.29,7.11-7.8l1.64,10.95a7.21,7.21,0,0,0,3.32-1.14A4.39,4.39,0,0,0,711,1043a4.19,4.19,0,0,0-3.52-3.59Zm-4.61,3.06c-2.49.35-4.39,2.06-4,4.73.38,2.52,2.85,3.82,5.24,3.55Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M695.89,1032.34l2.93-.23v-.06a5.11,5.11,0,0,1-3.68-4.29,4.34,4.34,0,0,1,3.27-4.56,5.33,5.33,0,0,1-4-4.62,4.53,4.53,0,0,1,1.88-4,8.56,8.56,0,0,1,4.28-1.07l9.06-.73.19,2.34-9.07.72a6.5,6.5,0,0,0-3,.67,2.85,2.85,0,0,0-1.38,2.66,3.7,3.7,0,0,0,1.94,2.8,5.76,5.76,0,0,0,3.83.54l8.2-.66.18,2.33-9.63.77a5.11,5.11,0,0,0-2.53.72,3,3,0,0,0-1.26,2.66,3.77,3.77,0,0,0,1.71,2.63,5.42,5.42,0,0,0,3.55.65l8.7-.7.19,2.34-15.19,1.21Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M705.23,996.56a6.65,6.65,0,0,1,4.57,6.41c.08,4.62-3.54,7-7.8,7.12s-8-2.36-8.13-6.92c-.07-4.74,3.85-6.84,8.08-6.79l.18,11.07a7.27,7.27,0,0,0,3.44-.69,4.38,4.38,0,0,0,2.25-3.94,4.19,4.19,0,0,0-3-4Zm-5,2.43c-2.52,0-4.63,1.45-4.59,4.15s2.32,4.17,4.72,4.22Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M697.18,990.34v-.06a5.21,5.21,0,0,1-3-4.87,4.81,4.81,0,0,1,2.55-4.22c1.32-.72,3.36-.58,4.86-.51L710,981l-.1,2.34-9.41-.4a6.15,6.15,0,0,0-2.84.3,3.12,3.12,0,0,0-1.48,2.55,4.1,4.1,0,0,0,1.39,3.22,6.09,6.09,0,0,0,4.06,1.37l8,.33-.1,2.34-15.22-.64.09-2.22Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M711.06,969.88v2.26c-.29,3.32-2.31,4.14-5.33,3.88l-8.79-.76L696.7,978l-1.86-.16.23-2.69-3.76-.32,0-2.35,4,.34.29-3.38,1.85.16-.29,3.38,9.18.79c1.76.15,2.73-.28,2.89-2.19v-1.81Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M748.6,853.77l-6.25-1.11-3.7,6.66,4.35,4.52-1,1.89L727.2,849.9l1.45-2.6,21.23,4.16Zm-18.39-3.61,7.08,7.58,3.08-5.53Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M752.68,837.58c2.33,2.1,3.13,5,1.4,7.82-2.41,3.83-6.78,3.39-10.16,1.27s-5.73-5.93-3.32-9.77c1.76-2.79,4.64-3.39,7.58-2.15l-.82,2c-1.81-.89-3.89-.74-5.05,1.11-1.69,2.7.65,5.23,2.81,6.59s5.53,2.58,7.22-.11c1.21-1.93.41-3.75-1.14-5.18Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M760.88,825.73c2.23,2.21,2.89,5.19,1,7.88-2.59,3.71-6.93,3.06-10.2.78s-5.44-6.21-2.85-9.93c1.89-2.7,4.81-3.15,7.68-1.77l-.92,2c-1.76-1-3.85-.93-5.1.87-1.82,2.61.4,5.25,2.49,6.71s5.4,2.85,7.22.25c1.3-1.87.58-3.73-.89-5.23Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M760.05,822.27c-3.34-2.6-4.75-6.74-1.94-10.37s7.19-2.88,10.36-.41c3.37,2.61,4.82,6.86,2,10.5S763.23,824.74,760.05,822.27Zm9.42-5.76a8.35,8.35,0,0,0-2.62-3,7.67,7.67,0,0,0-3.88-1.8,3.94,3.94,0,0,0-3.45,1.5,3.85,3.85,0,0,0-.41,4,8.72,8.72,0,0,0,2.54,2.93,8.91,8.91,0,0,0,3.83,1.87,3.88,3.88,0,0,0,4-5.57Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M783,806.38l-2.29-2,0,.05a5.18,5.18,0,0,1-.56,5.72,5,5,0,0,1-3.85,1.82,7.17,7.17,0,0,1-4.23-2.09l-7-6.08L766.6,802l7,6.09a5.82,5.82,0,0,0,2.44,1.44,3,3,0,0,0,2.7-.91,4,4,0,0,0,.89-3.4,5.71,5.71,0,0,0-2.14-3.41l-6.11-5.31,1.54-1.77,11.5,10Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M779.73,791.61l0,0a5.2,5.2,0,0,1,1-5.64,4.79,4.79,0,0,1,4.71-1.45c1.46.34,2.89,1.8,4,2.84l6,5.88-1.63,1.68-6.76-6.57a6,6,0,0,0-2.31-1.66,3.11,3.11,0,0,0-2.81.91,4.12,4.12,0,0,0-1.1,3.33,6.11,6.11,0,0,0,2.12,3.73l5.73,5.55-1.63,1.68-10.94-10.61,1.54-1.59Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M803.7,785.57l-1.51,1.68c-2.42,2.29-4.48,1.55-6.56-.65l-6.06-6.41-2,1.85-1.28-1.35,2-1.85-2.6-2.75,1.54-1.78,2.76,2.92,2.47-2.33,1.27,1.35-2.46,2.33,6.33,6.7c1.22,1.28,2.23,1.61,3.62.29l1.21-1.35Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M796.83,771l1.89,2.25,0,0a5.09,5.09,0,0,1,1.34-5.48,4.34,4.34,0,0,1,5.62,0,5.31,5.31,0,0,1,1.43-5.94,4.52,4.52,0,0,1,4.35-.8,8.64,8.64,0,0,1,3.35,2.86l5.85,7-1.79,1.51-5.85-7a6.64,6.64,0,0,0-2.27-2.05,2.88,2.88,0,0,0-3,.43,3.73,3.73,0,0,0-1.15,3.2,5.8,5.8,0,0,0,1.79,3.43l5.3,6.29L812,778.17l-6.22-7.39a5.23,5.23,0,0,0-2-1.65,3,3,0,0,0-2.9.52,3.8,3.8,0,0,0-1.15,2.93,5.42,5.42,0,0,0,1.53,3.26l5.62,6.68L805,784l-9.81-11.67Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M826.47,754.93l-.39-.54c-1.58-2.15-3.12-2.29-5.3-.69-1.55,1.13-2.68,2.69-1.78,4.64l-2,1c-1.54-2.82.59-5.6,2.85-7.25,1.33-1,3-1.94,4.66-1.54s3,1.89,4,3.3l3.49,4.77a10.88,10.88,0,0,0,2.16,2.4l-1.9,1.38-1.89-2.14A5.46,5.46,0,0,1,827.9,766c-2.3,1.68-5.23,1.85-7-.6a4.7,4.7,0,0,1-.22-5.08,14.27,14.27,0,0,1,4.2-4.17Zm1,1.43-1.58,1.15c-1.93,1.41-4.79,3.91-2.94,6.45,1.12,1.53,2.89,1.21,4.24.21a4.94,4.94,0,0,0,.86-7Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M831.79,747.66l.05,0a5.2,5.2,0,0,1,2.23-5.27A4.79,4.79,0,0,1,839,742c1.36.66,2.42,2.4,3.24,3.66l4.59,7.07-2,1.28-5.13-7.9a5.94,5.94,0,0,0-1.89-2.14,3.11,3.11,0,0,0-2.94.26,4.13,4.13,0,0,0-1.81,3,6.08,6.08,0,0,0,1.23,4.11l4.35,6.69-2,1.28-8.3-12.79,1.86-1.21Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M854.26,737.42l-.33-.58c-1.33-2.31-2.85-2.61-5.19-1.27-1.67,1-3,2.39-2.28,4.42l-2.07.78c-1.22-3,1.2-5.5,3.62-6.89,1.43-.82,3.16-1.61,4.8-1s2.72,2.21,3.59,3.72l2.94,5.12a10.92,10.92,0,0,0,1.89,2.62l-2,1.17-1.66-2.34a5.44,5.44,0,0,1-3.06,5.39c-2.47,1.42-5.39,1.27-6.9-1.36a4.67,4.67,0,0,1,.34-5.07,14.29,14.29,0,0,1,4.62-3.7Zm.88,1.53-1.69,1c-2.08,1.2-5.2,3.37-3.63,6.1.94,1.64,2.74,1.51,4.19.67a5,5,0,0,0,1.62-6.88Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M866.48,721.87l.55-.25.88,1.68-.21.11c-1.23.62-2.13,1.24-1.63,2.77a5.65,5.65,0,0,1,3,2.68,5,5,0,0,1,0,4.29,6.37,6.37,0,0,1-2.92,2.79,4.43,4.43,0,0,1-1,.4,8.17,8.17,0,0,0-1,.32,1.65,1.65,0,0,0-.81,2,1.32,1.32,0,0,0,1.6.63,19.35,19.35,0,0,0,3.32-1.41,17,17,0,0,1,4.24-1.53,3.58,3.58,0,0,1,3.6,1.94c1.75,3.46-2.12,6-4.74,7.31a13.18,13.18,0,0,1-4.26,1.48,3.65,3.65,0,0,1-3.85-1.82,3.09,3.09,0,0,1,.67-3.8,2.75,2.75,0,0,1-2.4-1.48c-.67-1.34-.19-2.32.65-3.38a5,5,0,0,1-3.59-2.7c-1.48-2.95.15-5.89,2.88-7.27a7.26,7.26,0,0,1,3.06-.81C863.91,723.7,864.64,722.8,866.48,721.87Zm.37,7.85a3.4,3.4,0,0,0-6.06,3.1,3.4,3.4,0,0,0,6.06-3.1Zm2.25,10.16-3.38,1.44a2.32,2.32,0,0,0-.54,2.59c1,1.88,4,.74,5.4,0a10.77,10.77,0,0,0,3.21-2.36,1.83,1.83,0,0,0,.36-2.2c-.7-1.39-2.64-.65-3.7-.15Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M887,727.44a6.65,6.65,0,0,1-4.13,6.7c-4.22,1.86-7.87-.53-9.58-4.43s-1-8.34,3.22-10.17c4.34-1.91,7.8.89,9.39,4.81l-10.12,4.46a7.19,7.19,0,0,0,2,2.9,4.38,4.38,0,0,0,4.5.55,4.2,4.2,0,0,0,2.55-4.34Zm-4.16-3.64c-1-2.32-3.13-3.7-5.6-2.62s-2.94,3.76-2.06,6Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M888.5,714.84l1,2.77.06,0a5.09,5.09,0,0,1,3.16-4.67,4.34,4.34,0,0,1,5.26,2,5.31,5.31,0,0,1,3.41-5.08,4.55,4.55,0,0,1,4.35.76,8.75,8.75,0,0,1,2.15,3.85L911,723l-2.2.79-3.07-8.55a6.6,6.6,0,0,0-1.42-2.71,2.9,2.9,0,0,0-2.94-.64,3.75,3.75,0,0,0-2.19,2.61,5.75,5.75,0,0,0,.49,3.84l2.78,7.74-2.2.79-3.27-9.09a5,5,0,0,0-1.35-2.26,3,3,0,0,0-2.9-.52,3.77,3.77,0,0,0-2.09,2.34,5.44,5.44,0,0,0,.3,3.59l3,8.22-2.21.79-5.15-14.34Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M925.51,714.52a6.65,6.65,0,0,1-5,6.07c-4.44,1.25-7.72-1.62-8.88-5.73s.22-8.38,4.61-9.63c4.56-1.28,7.6,2,8.64,6.08l-10.66,3a7.34,7.34,0,0,0,1.55,3.15,4.39,4.39,0,0,0,4.38,1.16,4.21,4.21,0,0,0,3.13-3.94Zm-3.61-4.19c-.66-2.43-2.59-4.1-5.19-3.36s-3.43,3.31-2.86,5.64Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M929.39,705.1h.06a5.2,5.2,0,0,1,3.93-4.17,4.78,4.78,0,0,1,4.73,1.37c1,1.09,1.43,3.09,1.76,4.55l1.85,8.23-2.29.52-2.06-9.2a6,6,0,0,0-1-2.66,3.12,3.12,0,0,0-2.85-.78,4.12,4.12,0,0,0-2.74,2.19,6.09,6.09,0,0,0-.27,4.27l1.75,7.79-2.29.51-3.33-14.88,2.17-.48Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M952.77,713.21l-2.18.58c-3.28.58-4.6-1.17-5.13-4.15L943.91,701l-2.66.48-.32-1.84,2.66-.47-.67-3.72,2.27-.65.7,4,3.34-.6.33,1.84-3.34.59,1.61,9.07c.31,1.75,1,2.57,2.87,2.24l1.75-.47Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            </g>
        </g>
        <g class="cirkel-2" data-name="Cirkel 2">
            <path class="components" @mouseover="activeClassname = 'components'" @mouseleave="activeClassname = null" d="M1000,900a100,100,0,0,1,86.62,50l86.55-50c-18.39-31.86-41.34-54.81-73.2-73.2-95.65-55.23-218-22.46-273.18,73.2l86.58,50A100,100,0,0,1,1000,900Z" transform="translate(-525.01 -525.13)" style="fill:#d6e0ef;stroke:#001f5e;stroke-miterlimit:10;stroke-width:2px"/>
            <path class="engineering" @mouseover="activeClassname = 'engineering'" @mouseleave="activeClassname = null" d="M900,1000a99.6,99.6,0,0,1,13.37-50l-86.58-50C808.4,931.88,800,963.24,800,1000c0,110.44,89.54,200,200,200V1100A100,100,0,0,1,900,1000Z" transform="translate(-525.01 -525.13)" style="fill:#d6e0ef;stroke:#001f5e;stroke-miterlimit:10;stroke-width:2px"/>
            <path class="service" @mouseover="activeClassname = 'service'" @mouseleave="activeClassname = null" d="M1173.17,900l-86.55,50A100,100,0,0,1,1000,1100h0v100c36.78,0,68.14-8.4,100-26.79C1195.63,1118,1228.4,995.68,1173.17,900Z" transform="translate(-525.01 -525.13)" style="fill:#d6e0ef;stroke:#001f5e;stroke-miterlimit:10;stroke-width:2px"/>
            <path d="M928.39,872c1.28,3.81.84,7.42-2.83,9.75-5,3.18-10.14-.35-12.85-4.63-2.85-4.49-3.36-10.16,1.68-13.36,3.5-2.22,7-1.16,9.78,1.58L922.36,867c-2-1.82-4.31-3-6.89-1.39-3.77,2.39-2.18,7.14-.27,10.16a13.06,13.06,0,0,0,3.56,3.81,5.12,5.12,0,0,0,5.64.33c2.66-1.69,2.63-4.48,1.74-7.11Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M930.25,869.55c-1.82-3.82-1.23-8.16,2.91-10.13s7.72.64,9.45,4.27c1.84,3.84,1.23,8.29-2.94,10.28S932,873.18,930.25,869.55Zm11-.92a8.24,8.24,0,0,0-1-3.85,7.69,7.69,0,0,0-2.66-3.35,4,4,0,0,0-3.75-.2,3.86,3.86,0,0,0-2.19,3.43,10.29,10.29,0,0,0,3.54,7.15,3.88,3.88,0,0,0,6.06-3.18Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M945.79,854.26l.88,2.81.06,0a5.09,5.09,0,0,1,3.36-4.54,4.34,4.34,0,0,1,5.18,2.18,5.3,5.3,0,0,1,3.61-4.94,4.56,4.56,0,0,1,4.31.94,8.54,8.54,0,0,1,2,3.94l2.72,8.67-2.23.7L963,855.33a6.57,6.57,0,0,0-1.31-2.77,2.89,2.89,0,0,0-2.91-.75,3.72,3.72,0,0,0-2.29,2.51,5.78,5.78,0,0,0,.33,3.86l2.46,7.84-2.24.7-2.89-9.21a5.16,5.16,0,0,0-1.25-2.32,3,3,0,0,0-2.88-.63,3.76,3.76,0,0,0-2.18,2.25,5.44,5.44,0,0,0,.15,3.6l2.61,8.33-2.23.7-4.56-14.54Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M974.7,867.3l-2.31.38-3.26-19.95,2.1-.35.47,2.9h.06a4.9,4.9,0,0,1,4.11-4c4.21-.69,6.63,3.2,7.22,6.81a10.34,10.34,0,0,1-.37,5.32,5.68,5.68,0,0,1-4.28,3.59,5,5,0,0,1-4.93-1.93Zm-1.77-10.8a4.39,4.39,0,0,0,4.66,3.58,3.72,3.72,0,0,0,2.78-2.34,8.32,8.32,0,0,0,.13-4.18c-.4-2.46-1.72-5.71-4.83-5.2-2,.33-3.25,2.39-3.36,4.32Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M985.54,852.8c-.21-4.23,2-8,6.59-8.24s6.88,3.56,7.08,7.58c.21,4.25-2.05,8.12-6.67,8.36S985.75,856.81,985.54,852.8Zm10.51,3.37a8.24,8.24,0,0,0,.55-3.93,7.71,7.71,0,0,0-1.17-4.12,4,4,0,0,0-3.39-1.63,3.85,3.85,0,0,0-3.33,2.33,8.71,8.71,0,0,0-.56,3.85,9,9,0,0,0,1.08,4.12,3.88,3.88,0,0,0,6.82-.62Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1005.4,847.62h.06a5.21,5.21,0,0,1,4.92-2.93,4.79,4.79,0,0,1,4.17,2.63c.71,1.33.52,3.37.44,4.86l-.51,8.42-2.33-.14.56-9.4a6.07,6.07,0,0,0-.25-2.85,3.14,3.14,0,0,0-2.53-1.53,4.09,4.09,0,0,0-3.23,1.34,6.07,6.07,0,0,0-1.45,4l-.47,8-2.34-.14.91-15.21,2.22.13Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1031.06,859a6.66,6.66,0,0,1-7.14,3.3c-4.55-.79-6.26-4.79-5.54-9s3.81-7.47,8.31-6.69c4.67.8,6,5.05,5.17,9.2L1021,854a7.4,7.4,0,0,0,0,3.5,4.41,4.41,0,0,0,3.45,2.94,4.19,4.19,0,0,0,4.52-2.2Zm-1.45-5.34c.46-2.47-.57-4.81-3.23-5.27s-4.52,1.5-5,3.85Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1039,852.57l.06,0a5.22,5.22,0,0,1,5.45-1.76,4.81,4.81,0,0,1,3.49,3.49c.38,1.45-.24,3.4-.66,4.84l-2.36,8.09-2.25-.65,2.64-9.05a5.86,5.86,0,0,0,.38-2.82,3.11,3.11,0,0,0-2.12-2.06,4.1,4.1,0,0,0-3.45.59,6.05,6.05,0,0,0-2.3,3.61l-2.23,7.66-2.25-.65,4.26-14.64,2.13.63Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1055.19,871.35l-2.16-.66c-3.1-1.21-3.31-3.38-2.21-6.21l3.21-8.21-2.51-1,.67-1.74,2.52,1,1.37-3.52,2.27.63-1.46,3.74,3.15,1.23-.67,1.74-3.16-1.24-3.35,8.58c-.65,1.65-.51,2.7,1.28,3.4l1.73.51Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1070,873.78a6.66,6.66,0,0,1-7.8,1.11c-4.14-2.06-4.62-6.39-2.72-10.21s5.8-6.06,9.88-4c4.24,2.11,4.3,6.57,2.31,10.3l-9.91-4.94a7.29,7.29,0,0,0-1,3.37,4.41,4.41,0,0,0,2.46,3.81,4.22,4.22,0,0,0,5-.81Zm.14-5.53c1.15-2.24.83-4.78-1.58-6s-4.77.14-5.92,2.25Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1079.49,869.87l0,0a5.23,5.23,0,0,1,5.73-.12,4.82,4.82,0,0,1,2.34,4.34c0,1.51-1.21,3.19-2,4.45l-4.58,7.08-2-1.28,5.12-7.91a5.81,5.81,0,0,0,1.18-2.59,3.1,3.1,0,0,0-1.44-2.58,4.12,4.12,0,0,0-3.48-.43,6.13,6.13,0,0,0-3.24,2.8l-4.33,6.71-2-1.28,8.28-12.79,1.87,1.2Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M917.57,1113.51l11.19,7.23L917.58,1138,906.64,1131l1.21-1.86,8.76,5.67,3.56-5.49-7-4.5,1.13-1.74,6.95,4.5,4.09-6.33-9-5.83Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M906.05,1120.19l0,0a5.22,5.22,0,0,1-5.7-.53,4.82,4.82,0,0,1-1.83-4.58c.22-1.49,1.56-3,2.51-4.19l5.36-6.51,1.81,1.49-6,7.27a5.9,5.9,0,0,0-1.46,2.45,3.11,3.11,0,0,0,1.13,2.72,4.1,4.1,0,0,0,3.41.82,6,6,0,0,0,3.54-2.41l5.07-6.16,1.8,1.49L906,1123.77l-1.71-1.41Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M883,1105.82l-.4-.45,1.33-1.34.17.17c1,1,1.83,1.66,3.13.72a5.62,5.62,0,0,1,1.65-3.67,5,5,0,0,1,4.09-1.27,6.31,6.31,0,0,1,3.54,2,4.61,4.61,0,0,1,.69.83c.15.2.4.62.59.82a1.65,1.65,0,0,0,2.14.17A1.33,1.33,0,0,0,900,1102a21.6,21.6,0,0,0-2.34-2.74,16.9,16.9,0,0,1-2.74-3.57,3.59,3.59,0,0,1,.77-4c2.77-2.71,6.34.22,8.39,2.32a13.22,13.22,0,0,1,2.7,3.62,3.64,3.64,0,0,1-.58,4.21,3.11,3.11,0,0,1-3.83.51,2.75,2.75,0,0,1-.68,2.73c-1.08,1-2.16.88-3.42.4a5,5,0,0,1-1.5,4.23c-2.35,2.3-5.66,1.63-7.79-.56a7.27,7.27,0,0,1-1.69-2.67C885.47,1107.72,884.39,1107.3,883,1105.82Zm7.37-2.71a3.33,3.33,0,0,0,0,4.75,3.29,3.29,0,0,0,4.8.1,3.4,3.4,0,0,0-4.78-4.85Zm9-5.2,2.38,2.79a2.32,2.32,0,0,0,2.64-.27c1.5-1.47-.51-4-1.6-5.15a10.55,10.55,0,0,0-3.22-2.35,1.81,1.81,0,0,0-2.2.31c-1.12,1.09.18,2.72,1,3.58Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M878.14,1100.57l1.65,2-2,1.69-1.66-2ZM892,1089.15l1.5,1.79-11.68,9.78-1.51-1.79Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M877.9,1091.39l0-.05a5.17,5.17,0,0,1-5.36-2,4.8,4.8,0,0,1-.57-4.89c.6-1.38,2.3-2.51,3.53-3.38l6.88-4.88,1.35,1.91-7.69,5.45a6,6,0,0,0-2.06,2,3.11,3.11,0,0,0,.39,2.93,4.11,4.11,0,0,0,3.07,1.69,6.07,6.07,0,0,0,4-1.4l6.52-4.61,1.35,1.91-12.44,8.81L875.6,1093Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M870.73,1064.22a6.66,6.66,0,0,1,7,3.5c2.24,4,.18,7.88-3.54,10s-8.21,1.7-10.42-2.29c-2.3-4.14.18-7.84,3.93-9.79l5.37,9.68a7.25,7.25,0,0,0,2.71-2.22,4.39,4.39,0,0,0,.13-4.53,4.19,4.19,0,0,0-4.55-2.14Zm-3.25,4.47c-2.22,1.2-3.4,3.46-2.09,5.82s4,2.58,6.15,1.5Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M864.36,1049.41a6.67,6.67,0,0,1,6.62,4.26c1.78,4.27-.69,7.86-4.62,9.5s-8.35.79-10.11-3.42c-1.82-4.37,1-7.78,5-9.3l4.26,10.22a7.27,7.27,0,0,0,2.94-1.91,4.4,4.4,0,0,0,.63-4.49,4.18,4.18,0,0,0-4.28-2.63Zm-3.73,4.09c-2.33.94-3.76,3.07-2.72,5.56s3.7,3,6,2.17Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M852.06,1047.22l3.4-1.08,0-.06c-2.19-.06-4.41-.71-5.15-3.06l-.21-.84,2.15-.68.15.45a4.28,4.28,0,0,0,3.47,3,6.09,6.09,0,0,0,3.34-.27l7.33-2.32.7,2.23-14.53,4.6Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M846.5,1037.44l.63,2.5-2.56.65-.63-2.5Zm17.42-4.27.58,2.26-14.78,3.74-.57-2.27Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M850.27,1029.06V1029a5.21,5.21,0,0,1-4-4.14,4.82,4.82,0,0,1,1.62-4.66c1.15-1,3.17-1.26,4.65-1.51l8.31-1.39.39,2.31-9.29,1.56a5.72,5.72,0,0,0-2.71.88,3.07,3.07,0,0,0-.93,2.8,4.09,4.09,0,0,0,2,2.85,6.05,6.05,0,0,0,4.25.51l7.87-1.33.39,2.31-15,2.53-.37-2.19Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M842.27,1003.06v-.6l1.89-.14v.24c.08,1.37.29,2.44,1.89,2.59a5.6,5.6,0,0,1,3.66-1.68,5,5,0,0,1,3.92,1.72A6.37,6.37,0,0,1,855,1009a4.37,4.37,0,0,1,0,1.08c0,.24-.11.73-.09,1a1.65,1.65,0,0,0,1.5,1.54,1.31,1.31,0,0,0,1.22-1.21,19.77,19.77,0,0,0,0-3.61,17.13,17.13,0,0,1,.28-4.49,3.59,3.59,0,0,1,3.22-2.53c3.86-.23,4.64,4.32,4.81,7.26a13.25,13.25,0,0,1-.34,4.49,3.63,3.63,0,0,1-3.2,2.8,3.08,3.08,0,0,1-3.22-2.12,2.75,2.75,0,0,1-2.31,1.61c-1.5.08-2.21-.75-2.85-1.94a5,5,0,0,1-3.89,2.21c-3.3.19-5.35-2.49-5.52-5.54a7.08,7.08,0,0,1,.48-3.12C842.93,1006.14,842.39,1005.12,842.27,1003.06Zm7.34,2.78a3.33,3.33,0,0,0-3.13,3.58,3.28,3.28,0,0,0,3.55,3.22,3.17,3.17,0,0,0,3.16-3.61A3.2,3.2,0,0,0,849.61,1005.84Zm10.21,2,0,3.66A2.31,2.31,0,0,0,862,1013c2.1-.12,2.27-3.38,2.18-4.94a10.88,10.88,0,0,0-.89-3.88,1.8,1.8,0,0,0-1.87-1.21c-1.56.09-1.65,2.17-1.61,3.34Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1142.05,1034.67c-3,0-4.89,1.93-5.68,4.7a6.27,6.27,0,0,0,0,3.83,3.44,3.44,0,0,0,2.19,1.94c2,.57,3.27-.84,4.2-2.45l1.75-3a7.62,7.62,0,0,1,2.61-3.12,5.07,5.07,0,0,1,4.12-.57c3.81,1.09,4.51,4.85,3.53,8.28a7.24,7.24,0,0,1-6.87,5.65l0-2.42c2.29.19,4.28-1.74,4.88-3.85.54-1.87.24-4.52-1.92-5.14s-3.21,1-4.17,2.68l-1.58,2.76a8.29,8.29,0,0,1-2.56,3.17,5.09,5.09,0,0,1-4.45.72,5.79,5.79,0,0,1-3.86-3.51,8.81,8.81,0,0,1,0-5.58c1.16-4,3.7-6.18,7.92-6.4Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1130.37,1061.92a6.64,6.64,0,0,1-1.59-7.71c1.8-4.26,6.09-5,10-3.35s6.41,5.41,4.63,9.61c-1.84,4.37-6.29,4.7-10.13,2.94l4.31-10.19a7.27,7.27,0,0,0-3.42-.77,4.4,4.4,0,0,0-3.65,2.7,4.2,4.2,0,0,0,1.12,4.9Zm5.52-.21c2.31,1,4.82.55,5.88-1.94s-.44-4.75-2.62-5.76Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1137.41,1072.25l-3.16-1.68,0,.06c1.49,1.6,2.57,3.64,1.42,5.82l-.46.74-2-1.06.23-.42a4.28,4.28,0,0,0-.28-4.6,6,6,0,0,0-2.53-2.2l-6.78-3.6,1.1-2.07,13.45,7.15Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1115.92,1078.5l1.36-2.06,15.71,3.89-1.45,2.18-12.68-3.29,8,10.32-1.16,1.75Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1110.27,1086.26l1.42-1.85,12.09,9.28-1.42,1.86Zm14.16,11,1.57-2,2.09,1.61-1.57,2Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1104,1101.34c-1.94-2.46-2.24-5.5-.06-7.94,3-3.38,7.25-2.22,10.23.44s4.65,6.82,1.63,10.19c-2.2,2.47-5.15,2.57-7.83.85l1.14-1.87c1.64,1.18,3.72,1.38,5.18-.25,2.12-2.37.23-5.26-1.67-7s-5-3.48-7.14-1.11c-1.52,1.7-1,3.63.26,5.3Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            <path d="M1091.91,1111.28a6.65,6.65,0,0,1,1.81-7.66c3.42-3.1,7.64-2,10.49,1.18s3.53,7.61.15,10.67-7.68,1.61-10.43-1.6l8.21-7.43a7.41,7.41,0,0,0-2.79-2.14,4.39,4.39,0,0,0-4.44.91,4.21,4.21,0,0,0-1.05,4.92Zm5.1,2.15c1.67,1.88,4.14,2.52,6.14.71s1.61-4.49.06-6.33Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
        </g>
        <g class="cirkel-1" data-name="Cirkel 1" @mouseover="activeClassname = 'cirkel-1'" @mouseleave="activeClassname = null">
            <circle cx="474.99" cy="474.87" r="100" style="fill:#fff;stroke:#001f5e;stroke-miterlimit:10;stroke-width:2px"/>
            <g>
                <path d="M981.29,1010.13l-6.11-11.34-4.71,5.85v5.49h-2.58V989.55h2.58v11.94l9.27-11.94h2.79l-5.55,7.2,7.17,13.38Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M986.33,1010.13V989.55h2.35v20.58Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1003.4,1000.68V1000c0-2.67-1.17-3.69-3.87-3.69-1.92,0-3.75.6-4.17,2.7l-2.19-.36c.42-3.18,3.78-4.17,6.57-4.17,1.65,0,3.54.18,4.68,1.5s1.26,3.27,1.26,5v5.91a11.23,11.23,0,0,0,.33,3.21h-2.34l-.27-2.85a5.46,5.46,0,0,1-5.34,3.15c-2.85,0-5.31-1.59-5.31-4.62a4.71,4.71,0,0,1,2.82-4.23,14.26,14.26,0,0,1,5.85-.9Zm0,1.77h-1.95c-2.4,0-6.17.33-6.17,3.48,0,1.89,1.61,2.67,3.29,2.67a5,5,0,0,0,4.83-5.16Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1011.59,997.71h.06a5.2,5.2,0,0,1,4.74-3.21,4.81,4.81,0,0,1,4.32,2.37c.78,1.29.72,3.33.72,4.83v8.43h-2.34v-9.42a5.94,5.94,0,0,0-.42-2.82,3.11,3.11,0,0,0-2.61-1.38,4.1,4.1,0,0,0-3.15,1.53,6.06,6.06,0,0,0-1.2,4.11v8h-2.34V994.89h2.22Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
                <path d="M1032.86,1010.25l-2.25.18c-3.33,0-4.32-1.95-4.32-5v-8.82h-2.7v-1.86h2.7V991l2.34-.24v4H1032v1.86h-3.39v9.21c0,1.77.51,2.7,2.43,2.7l1.8-.15Z" transform="translate(-525.01 -525.13)" style="fill:#001f5e"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        data() {
            return {
                activeClassname: false,
            }
        },
        watch: {
            activeClassname() {
                this.$emit('classChange', this.activeClassname);
            }
        }
    }
</script>