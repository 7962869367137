<template>
    <div class="page-wrapper project-page">
        <ProjectHeader :content="content"/>
        <component 
            v-for="(component, index) in content.contentBlocks" 
                :is="component.name"
                :content="component.data" 
                :index="index"
                :nextComponentType="content.contentBlocks[index+1] ? content.contentBlocks[index+1].name : null"
                :key="component.name+'_'+index"
        />
        <!-- <section class="default-layout more-projects">
            <ProjectsOverview :content="content.relatedProjects"/>
        </section> -->
     </div>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'
    import buttonArrow from '@/js/svg/buttonArrow.vue'
    import ProjectHeader from '@/js/components/Project/Header.vue'
    
    export default {
        mixins: [DefaultPage],
        components: {
            ProjectHeader,
            buttonArrow
        },
        data: function() {
            return {
              
            }
        },
        computed: {
            
        },
        methods: {
          
        },
        mounted() {
        
        },
        created() {
           this.menuClass = 'blue-lighter';
        }

    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';
    .project-page {

        .page-header {
            
        }

        .image-component {
            .img-wrapper {
                background: transparent !important;

                .content {
                    @include grid-12;
                    
                    img {
                        background-color: $grey;
                        grid-column: 1/ span 12;
                        @include tablet {
                            grid-column: 3/ span 8;
                        }
                    }
                }
            }
        }

        .more-projects {
            margin-bottom: $defaultPadding*3.5;

            h4 {
                @include font-big;
            }

            .button-wrapper {
                display: flex;
                justify-content: flex-end;
            }

            @include tablet {
                .button-wrapper {
                    margin-top: -#{$defaultPadding*1.5};
                }
            }
        }
    }    
</style>

