<template>
  <section class="text-component" >
  <div class="default-layout form">
    <div class="form__success" ref="successWrapper">
      <h2 class="form__title" v-html="successMsg"></h2>
    </div>

    <div class="form__wrapper" ref="formWrapper">
      <h2 class="form__title" v-text="content.title"></h2>
      <div class="form__text" v-html="content.body"></div>

      <FormulateForm
        v-model="formValues"
        @submit="submitHandler"
        @submit-raw="preSubmitHandler"
        #default="{ isLoading }"
      >
        <div class="form__grid">
          <div class="form__row">
            <FormulateInput
              type="text"
              label="Naam"
              validation="required"
              name="fromName"
              :validation-messages="{ required: 'Naam is verplicht' }">
              <template #label="{ label, id }">
                <label :for="id">
                  {{ label }}<span class="required">*</span>
                </label>
              </template>
            </FormulateInput>
          </div>

          <div class="form__row">
            <FormulateInput type="text" label="Bedrijf" validation="required" name="company" :validation-messages="{ required: 'Bedrijf is verplicht' }">
              <template #label="{ label, id }">
                <label :for="id">
                  {{ label }}<span class="required">*</span>
                </label>
              </template>
            </FormulateInput>
          </div>

          <div class="form__row">
            <FormulateInput type="email" label="E-mail" validation="required|email" name="fromEmail">
              <template #label="{ label, id }">
                <label :for="id">
                  {{ label }}<span class="required">*</span>
                </label>
              </template>
            </FormulateInput>
          </div>

          <div class="form__row">
            <FormulateInput type="text" label="Telefoonnummer" validation="required" name="phone" :validation-messages="{ required: 'Telefoonnummer is verplicht' }">
              <template #label="{ label, id }">
                <label :for="id">
                  {{ label }}<span class="required">*</span>
                </label>
              </template>
            </FormulateInput>
          </div>

          <div class="form__row">
            <FormulateInput type="text" label="Adres" name="address">
            </FormulateInput>
          </div>


          <div class="form__row">
            <FormulateInput type="textarea" label="Uw vraag" name="message">
              <template #label="{ label, id }">
                <label :for="id">
                  {{ label }}
                </label>
              </template>
            </FormulateInput>
          </div>
        </div>
        <FormulateInput type="submit" :disabled="isLoading" :label="isLoading ? 'Bezig met versturen' : 'Bevestig'">
          <template #element="{ label }">
            <button class="formulate-submit-button" :class="{ 'loading': isLoading }">
              {{ label }}
            </button>
          </template>
        </FormulateInput>
      </FormulateForm>
    </div>
  </div>
</section>
</template>

<script>
export default {
  props: {
    successMsg: {
      type: String,
      default: null
    },
    content: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formValues: {
        fromName: '',
        company: '',
        address: '',
        phone: '',
        fromEmail: '',
        message: '',
        authorization: false,
      }
    }
  },
  methods: {
    preSubmitHandler(data) {
      if (data && data.form && data.form.hasErrors) {
        // Scroll up to first error
        let errorEl = document.querySelector('.formulate-input[data-is-showing-errors=true]');

        if (errorEl) {
          this.ScrollIntoView(errorEl, { time: 400 });
        }
      }
    },
    async submitHandler(data) {
      var form_data = new FormData();

      form_data.append(window.csrfTokenName, window.csrfTokenValue);
      form_data.append('action', 'contact-form/send');

      form_data.append('fromName', data['fromName']);
      form_data.append('message', data['message']);
      form_data.append('fromEmail', data['fromEmail']);

      for (var key in data) {
        let value = data[key];

        if (key != 'fromName' || key != 'message' || key != 'fromEmail') {
          form_data.append(`message[${key}]`, value);
        }
      }

      await this.$http.post('/', form_data).then((result) => {
        let formWrapper = this.$refs['formWrapper'];
        let formWrapperHeight = formWrapper.scrollHeight;

        formWrapper.style.maxHeight = formWrapperHeight + 'px';

        this.$nextTick(() => {
          window.location.href = '/bedankt';
        });
      });
    }
  }
}
</script>
