<template>
    <div class="popout sector-popout" 
        :class="{'show': showSectorPopup}" 
        @mouseover="onMouseOver"
        @mouseleave="onMouseleave"
    >
        <span class="text">Sectoren</span>
        <div class="triangle" @mouseover="onMouseOver"></div>
        <div class="popout" @mouseover="onMouseOver" @mouseleave="onMouseleave">
            <SectorPopoutMenu />
        </div>
    </div>
</template>

<script>
    import SectorPopoutMenu from '@/js/components/shared/SectorPopoutMenu.vue'

    export default {
        components: {
            SectorPopoutMenu
        },
        data() {
            return {
                showSectorPopup: false,
                showSectorPopupTimer: null,
            };
        },
        computed: {
            showCurtains: { 
                get() {
                    return this.$store.state.showCurtains;
                },
                set(state) {
                    this.$store.state.showCurtains = state;
                }
            },
            disableMainNav: { 
                get() {
                    return this.$store.state.disableMainNav;
                },
                set(state) {
                    this.$store.state.disableMainNav = state;
                }
            }
        },
        methods: {
            onMouseOver() {
                window.clearTimeout(this.showSectorPopupTimer)
                this.showSectorPopup = true;
                this.showCurtains = true;
                this.disableMainNav = true;
            },
            onMouseleave() {
                window.clearTimeout(this.showSectorPopupTimer)
                this.showSectorPopupTimer = window.setTimeout(() => {
                    this.hide();
                }, 100);
            },
            hide() {
                this.showSectorPopup = false;
                this.showCurtains = false;
                this.disableMainNav = false;
            }
        }
    };
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .sector-popout {
        z-index: 7;
        display: flex;
        flex-flow: column;
        align-content: center;

        .triangle, .popout {
            opacity: 0;
            transform: translateY(1em);
            transition: all 0.35s cubic-bezier(0.65, 0, 0.35, 1);
            pointer-events: none;
        }
    
        .triangle {
            width: 100%;
            position: relative;
            display: inline-block;

            &::before {
                display: block;
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid $blue-lighter;
                left: 50%;
                transform: translate3d(-50%,6.5px,0);
            }
        }
        
        .popout {
            background-color: $blue-lighter;
            width: 720px;
            height: 280px;
            position: absolute;
            top: calc(100% + 16px);
            left: 0;
            overflow: hidden;

            .sector-menu {
                width: 100%;
                height: 100%;
            }
        }

        &.show {
            .triangle, .popout {
                opacity: 1;
                pointer-events: all;
                transform: translateY(0);
            }
        }
    }

</style>