import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store.js'

import ViewHelper from '@/js/views/ViewHelper.vue'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            //    '/lang/slug/'
            path: '/:part1?/:part2?/',
            component: ViewHelper,
        },
    ]
})

router.beforeEach((to, from, next) => {
    if(store.state.currentLanguage != 'nl' && to.path.indexOf(`/${store.state.currentLanguage}/`) === -1) {
        const redirectTo = `/${store.state.currentLanguage}${to.fullPath}`;
        next({ path: redirectTo });
    } else {
        next();
    }
})

router.afterEach((to, from) => {
    if(window.fbq !== undefined) {
        window.fbq('track', 'PageView');
    }
})

export default router;