var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"text-component"},[_c('div',{staticClass:"default-layout form"},[_c('div',{ref:"successWrapper",staticClass:"form__success"},[_c('h2',{staticClass:"form__title",domProps:{"innerHTML":_vm._s(_vm.successMsg)}})]),_vm._v(" "),_c('div',{ref:"formWrapper",staticClass:"form__wrapper"},[_c('h2',{staticClass:"form__title",domProps:{"textContent":_vm._s(_vm.content.title)}}),_vm._v(" "),_c('div',{staticClass:"form__text",domProps:{"innerHTML":_vm._s(_vm.content.body)}}),_vm._v(" "),_c('FormulateForm',{on:{"submit":_vm.submitHandler,"submit-raw":_vm.preSubmitHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"form__grid"},[_c('div',{staticClass:"form__row"},[_c('FormulateInput',{attrs:{"type":"text","label":"Naam","validation":"required","name":"fromName","validation-messages":{ required: 'Naam is verplicht' }},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var label = ref.label;
var id = ref.id;
return [_c('label',{attrs:{"for":id}},[_vm._v("\n                  "+_vm._s(label)),_c('span',{staticClass:"required"},[_vm._v("*")])])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__row"},[_c('FormulateInput',{attrs:{"type":"text","label":"Bedrijf","validation":"required","name":"company","validation-messages":{ required: 'Bedrijf is verplicht' }},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var label = ref.label;
var id = ref.id;
return [_c('label',{attrs:{"for":id}},[_vm._v("\n                  "+_vm._s(label)),_c('span',{staticClass:"required"},[_vm._v("*")])])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__row"},[_c('FormulateInput',{attrs:{"type":"email","label":"E-mail","validation":"required|email","name":"fromEmail"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var label = ref.label;
var id = ref.id;
return [_c('label',{attrs:{"for":id}},[_vm._v("\n                  "+_vm._s(label)),_c('span',{staticClass:"required"},[_vm._v("*")])])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__row"},[_c('FormulateInput',{attrs:{"type":"text","label":"Telefoonnummer","validation":"required","name":"phone","validation-messages":{ required: 'Telefoonnummer is verplicht' }},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var label = ref.label;
var id = ref.id;
return [_c('label',{attrs:{"for":id}},[_vm._v("\n                  "+_vm._s(label)),_c('span',{staticClass:"required"},[_vm._v("*")])])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__row"},[_c('FormulateInput',{attrs:{"type":"text","label":"Adres","name":"address"}})],1),_vm._v(" "),_c('div',{staticClass:"form__row"},[_c('FormulateInput',{attrs:{"type":"textarea","label":"Uw vraag","name":"message"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var label = ref.label;
var id = ref.id;
return [_c('label',{attrs:{"for":id}},[_vm._v("\n                  "+_vm._s(label)+"\n                ")])]}}],null,true)})],1)]),_vm._v(" "),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"label":isLoading ? 'Bezig met versturen' : 'Bevestig'},scopedSlots:_vm._u([{key:"element",fn:function(ref){
var label = ref.label;
return [_c('button',{staticClass:"formulate-submit-button",class:{ 'loading': isLoading }},[_vm._v("\n              "+_vm._s(label)+"\n            ")])]}}],null,true)})]}}]),model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }