<template>
    <span class="home-header-wrapper">
        <header>
            <div class="default-layout logo-and-slogan">
                <intersect @enter="showLogo = 1;" @leave="showLogo = false" :threshold="[0]">
                    <h1>
                        <logoSVG :color="'#fff'"/>
                    </h1>
                </intersect>
                <span class="slogan">
                    Hydraulic and automation
                </span>
            </div>
            <div class="link-block">
                <div class="content" ref="headerBackground">
                    <img :data-srcSet="content.headerImage.srcset" data-sizes="auto" class="lazyload">
                    <span v-if="content.headerVideo" class="iframe-wrapper" :class="{'playing': videoPlaying}" v-html="content.headerVideo"></span>
                </div>
            </div>
        </header>
    </span>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'
    import Intersect from 'vue-intersect';
    import logoSVG from '@/js/svg/logo.vue'
    import buttonArrow from '@/js/svg/buttonArrow.vue'

    export default {
        mixins: [DefaultPage],
        components: {
            Intersect,
            logoSVG,
            buttonArrow
        },
        data: function() {
            return {
                showLogo: 0,
                videoPlaying: 0,
            }
        },
        computed: {

        },
        methods: {
            videoStarted() {
                this.videoPlaying = 1;
            },
            onScroll(e) {
                if(window.innerWidth > 768) {
                    let bgImgEl = this.$refs['headerBackground'];

                    const doc = document.documentElement;
                    const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

                    window.requestAnimationFrame(() => {
                        bgImgEl.style.transform = `translateY(${top/25}%)`;
                    });
                }
            }
        },
        mounted() {
            this.$store.commit('setMenuLogoVisibility', false);
            window.addEventListener("scroll", this.onScroll);

            var mq = window.matchMedia("(min-width: 768px)")

            if(this.content.headerVideo && mq.matches) {
                const videoIframe = this.$el.querySelector('.iframe-wrapper iframe');

                if(videoIframe) {
                    this.player =  new Vimeo.Player(videoIframe);
                    this.player.on('play', this.videoStarted);
                }
            } else if(this.content.headerVideo) {
                const iFrameEl = this.$el.querySelector(".iframe-wrapper");
                iFrameEl.parentElement.removeChild(iFrameEl);
            }
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll);
        },
        watch: {
            'showLogo'(val) {
                if(val) {
                    this.$store.commit('setMenuLogoVisibility', false);
                } else {
                    this.$store.commit('setMenuLogoVisibility', true);
                }
            }
        }

    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .home-header-wrapper {
        display: block;
        background-color: $blue;

        header {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: flex-start;
            overflow: hidden;

            .logo-and-slogan {
                flex: 1 0 auto;
                width: 100%;
                max-width: 720px;
                color: $white;
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                padding-bottom: $defaultPadding*2;

                h1, .slogan {
                    transform: translateY(3rem);
                    opacity: 0;
                    animation-name: homeAnimation;
                    animation-duration: .75s;
                    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
                    animation-fill-mode: forwards;
                }
            
                h1 {
                    flex: 1 0 100%;
                    svg {
                        width: 100%;
                    }

                    animation-delay: .4s;

                }

                .slogan {
                    @include font-xl;

                    animation-delay: .45s;
                }

                @include tablet {
                    @include defaultComponentPadding;
                }
            }

            .link-block {
                transform: translateY(3rem);
                opacity: 0;
                animation-name: homeAnimation;
                animation-duration: .8s;
                animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
                animation-fill-mode: forwards;
                animation-delay: .9s;

                flex: 1 0 100%;
                align-self: flex-end;

                .content {
                    position: relative;
                    overflow: hidden;
                    min-height: 270px;

                    img, .iframe-wrapper {
                        z-index: 1;
                        position: absolute;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }

                    .iframe-wrapper {
                        opacity: 0;
                        z-index: 2;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: none;
                        transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                        
                        iframe {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            height: 139%;
                            width: 139%;
                            transform: translate3d(-50%,-50%,0);
                        }

                        &.playing {
                            opacity: 1;
                        }

                        @include tablet {
                            display: block;
                        }
                    }
                }

                @include defaultLayout;
                @include tablet {
                    @include aspect-ratio(1320,1000);
                }

                @include desktop {
                    @include aspect-ratio(1320,560);
                }
            }

            @include tablet {
                padding: 0 $defaultPadding;
            }
        }

        .sector-menu {
            z-index: 2;

            li {
                display: flex;

                a {
                    transform: translateY(3rem);
                    opacity: 0;
                    animation-name: homeAnimation;
                    animation-duration: 1s;
                    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
                    animation-fill-mode: forwards;
                    animation-delay: 1.1s;
                }
            }
        }

        .intro-text {
            @include grid-12;
            @include bigComponentPadding;
            @include font-big;

            .text {
                @include tablet {
                    grid-column: 3/ span 8;
                }
            }
        }
    } 

    @keyframes homeAnimation{
        from {
            transform: translateY(3em);
            opacity: 0;
        } 
        to{
            transform: translateY(0);
            opacity: 1;
        } 
    }
</style>

