import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // Settings
        debug: false,
        websiteData: null,

        isHome: false,
        initializing: true,
        pageLoading: true,

        menuBgClass: '',
        showMenuLogo: true,

        // Language
        defaultLanguage: 'nl',
        currentLanguage: 'nl',
        availableLanguages: null,
        availableTranslations: null,

        disableMainNav: false,
        showCurtains: false,
        curtainsOverMenu: true,

        newsPagination: 1,

        // API endpoints
        apiEndpoints: {
            'website': {
                url: '/api/v1/website.json'
            },
            'page': {
                url: '/api/v1/page/'
            },
            'news': {
                url: '/api/v1/news.json'
            }
        },
    },
    mutations: {
        setInitializing: function(state, status) {
            state.initializing = status;
        },
        setPageLoading: function(state, status) {
            state.pageLoading = status;
        },
        setWebsiteData: function(state, websiteData) {
            state.websiteData = websiteData;
        },
        setAvailableLanguages: function(state, arrayOfLanguages) {
            state.availableLanguages = arrayOfLanguages;
        },
        setCurrentLanguage: function(state, language) {
            state.currentLanguage = language;
        },
        setAvailableTranslations: function(state, translations) {
            state.availableTranslations = translations;
        },
        setIsHome: function(state, bool) {
            state.isHome = bool;
        },
        setMenuBgClass: function(state, className) {
            state.menuBgClass = className;
        },
        setMenuLogoVisibility: function(state, bool) {
            state.showMenuLogo = bool;
        },
        setNewsPagination: function(state, number) {
            state.newsPagination = number;
        },
    },
    actions: {
        setLanguage ({ commit, state }, language) {
            if (state.availableLanguages.indexOf(language) > -1) {
                commit('setCurrentLanguage', language);
                return true;
            } else {
                // language does not exist / 404
                return false;
            }
        },
    },
    getters: {
        langPrefix(state) {
            if(state.currentLanguage == state.defaultLanguage) return '/';
            return '/'+state.currentLanguage+'/';
        }
    }
})
