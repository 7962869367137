<template>
    <div class="page-wrapper extra-space-top projects-overview-page">
        <section class="default-layout">
            <h1>Onze cases</h1>
        </section>
        <ProjectsOverview :content="content.projects" :full="true"/>
    </div>
</template>

<script>
    import DefaultPage from '@/js/mixins/DefaultPage.vue'
    import ProjectsOverview from '@/js/components/contentBlocks/ProjectsOverview.vue'
    
    export default {
        mixins: [DefaultPage],
        components: {
            
        },
        data: function() {
            return {
              
            }
        },
        computed: {
            
        },
        methods: {
          
        },
        mounted() {
        
        },
        created() {
           
        }

    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';
    
    .projects-overview-page {
        background-color: $blue-lighter;

        h1 {
            padding-bottom: 1em;
        }

        .projects-overview-component {
            margin-top: 0;
            margin-bottom: 0;
            padding-bottom: $defaultPadding*4;
        }
    }
</style>

