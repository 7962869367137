// Polyfills
import 'es6-promise/auto'; // needed for store but also other places promises are used
import 'polyfill-array-includes';

// Import Vue
import Vue from 'vue';

// Import Vue App, routes, store
import App from './App.vue';

import router from './router/router';

import store from './store/store'

// Axios (ajax)
import axios from 'axios'
Vue.prototype.$http = axios;

import scrollIntoView from 'scroll-into-view'
Vue.prototype.ScrollIntoView = scrollIntoView;

import VueFormulate from '@braid/vue-formulate'

Vue.use(VueFormulate)

// lazysizes
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
});
