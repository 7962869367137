<template>
    <span class="project-header-wrapper">
        <div class="default-layout">
            <header class="content">
                <div class="text">
                    <div class="text-wrapper">
                        <ul class="related-pages">
                            <router-link v-for="(relatedPage, index) in content.relatedPages"
                                class="label"
                                :key="'relatedPage_'+index"
                                tag="li" 
                                :to="{ path: relatedPage.uri }" 
                            >
                                {{ relatedPage.title }}
                            </router-link>
                        </ul>
                        <span>
                            <h1>{{ content.title }}</h1>
                            <span class="sub-title">{{ content.subTitle }}</span>
                        </span>
                    </div>
                </div>
            </header>
            <div class="content background" ref="headerBackground">
                <img :data-srcSet="content.headerImage.srcset" data-sizes="auto" class="lazyload">
            </div>
        </div>
    </span>
</template>

<script>
    import Intersect from 'vue-intersect';
    
    export default {
        components: {
            Intersect,
        },
        props: {
            content: {
                type: Object | Array,
                required: true
            }
        },
        computed: {
            
        },
        methods: {
            onScroll(e) {
                if(window.innerWidth > 768) {
                    let bgImgEl = this.$refs['headerBackground'];

                    const doc = document.documentElement;
                    const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

                    window.requestAnimationFrame(() => {
                        bgImgEl.style.transform = `translateY(${top/25}%)`;
                    });
                }
            }
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll);
        }
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .project-header-wrapper {
        display: block;
        background-color: $blue-lighter;
        overflow: hidden;

        .default-layout {
            .background {
                width: 100%;
                overflow: hidden;

                img {
                    display: block;
                    @include lazyloaded;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            header {
                width: 100%;
                overflow: hidden;
                display: flex;
                align-items: flex-end;

                .text {
                    position: relative;
                    @include grid-12;

                    .text-wrapper {
                        @include fluid-type(min-height, 480px, 1600px, 250px, 350px);
                        background-color: $blue;
                        position: relative;
                        z-index: 1;
                        grid-column: 1/ span 12;
                        display: flex;
                        flex-flow: row wrap;
                        align-content: space-between;
                        padding: $defaultPadding*1.4 $defaultPadding;

                        .related-pages {
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            display: flex;
                            flex-flow: row wrap;
                            margin-bottom: $defaultPadding*1.5;

                            .label {
                                cursor: pointer;
                                border: 1px solid $blue-light;
                                border-radius: 15px;
                                color: $blue-light;
                                padding: $defaultPadding/3 $defaultPadding/2 0 $defaultPadding/2;
                                margin-right: $defaultPadding/2.5;
                                margin-bottom: $defaultPadding/2;
                                text-decoration: none;

                                transition: all .15s cubic-bezier(0.65, 0, 0.35, 1);

                                &:hover {
                                    color: $white;
                                    border-color: $white;
                                }
                            }
                        }

                        h1 {
                            @include font-xl;
                            color: $white;
                            padding-bottom: 0;
                        }

                        .sub-title {
                            @include font-xl;
                            color: $blue-light;
                        }
                    }

                    @include tablet {

                        .text-wrapper {
                            grid-column: 3/ span 8;
                            padding: $defaultPadding*1.2 $defaultPadding*2;
                        }
                    }
                }
            }

            @include tablet {
                @include aspect-ratio(1321, 805);
            }
        }

        
    } 
</style>

