<script>
    export default {
        // mixins: [TranslationHelper],
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        components: {
            TextComponent: () => import('@/js/components/contentBlocks/Text.vue' /* webpackChunkName: 'dist/js/partials/Text' */),
            TextWithImage: () => import('@/js/components/contentBlocks/TextWithImage.vue' /* webpackChunkName: 'dist/js/partials/TextWithImage' */),
            TextWithImageBlue: () => import('@/js/components/contentBlocks/TextWithImageBlue.vue' /* webpackChunkName: 'dist/js/partials/TextWithImageBlue' */),
            ImageComponent: () => import('@/js/components/contentBlocks/Image.vue' /* webpackChunkName: 'dist/js/partials/image' */),
            VideoComponent: () => import('@/js/components/contentBlocks/VideoComponent.vue' /* webpackChunkName: 'dist/js/partials/VideoComponent' */),
            QuoteComponent: () => import('@/js/components/contentBlocks/Quote.vue' /* webpackChunkName: 'dist/js/partials/Quote' */),
            CtaComponent: () => import('@/js/components/contentBlocks/Cta.vue' /* webpackChunkName: 'dist/js/partials/Cta' */),
            TeamComponent: () => import('@/js/components/contentBlocks/Team.vue' /* webpackChunkName: 'dist/js/partials/Team' */),
            ProjectComponent: () => import('@/js/components/contentBlocks/Project.vue' /* webpackChunkName: 'dist/js/partials/Project' */),
            ProjectsOverview: () => import('@/js/components/contentBlocks/ProjectsOverview.vue' /* webpackChunkName: 'dist/js/partials/ProjectsOverview' */),
            NewsOverview: () => import('@/js/components/contentBlocks/NewsOverview.vue'),
            PerdokCircle: () => import('@/js/components/contentBlocks/PerdokCircle.vue' /* webpackChunkName: 'dist/js/partials/PerdokCircle' */),
            Grid3Col: () => import('@/js/components/contentBlocks/Grid3Col.vue' /* webpackChunkName: 'dist/js/partials/Grid3Col' */),
            Grid4Col: () => import('@/js/components/contentBlocks/Grid4Col.vue' /* webpackChunkName: 'dist/js/partials/Grid4Col' */),
            BrandsGrid: () => import('@/js/components/contentBlocks/BrandsGrid.vue' /* webpackChunkName: 'dist/js/partials/BrandsGrid' */),
            ContactForm: () => import('@/js/components/contentBlocks/ContactForm.vue' /* webpackChunkName: 'dist/js/partials/ContactForm' */),
        },
        computed: {
            title() {
                return this.content.title ? this.content.title : '';
            },
            slug() {
                return this.content.slug ? this.content.slug : '';
            },
            pageClass() {
                return this.content.slug ? 'page-'+this.content.slug : '';
            },
            menuClass: {
                set(className) {
                    this.$store.commit('setMenuBgClass', className)
                },
                get() {
                    return this.$store.state.menuBgClass;
                }
            }
        },
        mounted() {

        }
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .page-wrapper {
        padding-top: 3rem;

        @include menuSwitch {
            padding-top: 5rem;
        }

        &.extra-space-top {
            padding-top: 9rem;
        }
    }
</style>
