<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <polygon :fill="color" points="10.099 28 17 20.99 10.099 14 9.249 14.884 14.774 20.414 0 20.414 0 21.545 14.794 21.545 9.249 27.137" transform="translate(0 -10)"/>
  </svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                required: false,
                default: '#001E5A'
            }
        }
    }
</script>