<template>
    <div class="main-nav-wrapper" :class="[{'open':open}, $store.state.menuBgClass, position, {'show': show}]">
        <nav class="main-nav desktop">
            <div class="curtains" :class="{'show': showCurtains}"></div>
            <div class="menu-items">
                <template v-for="(menuItem, index) in menuItems"
                >
                    <SectorPopout v-if="menuItem.title === 'Sectoren'" :key="'menuitem_'+index"/>
                    <a v-else-if="menuItem.uri && menuItem.external" target="_blank" :href="menuItem.uri" :key="'menuitem_'+index">
                        {{ menuItem.title }}
                    </a>
                    <router-link
                        v-else-if="menuItem.uri"
                        :to="{ path: '/'+menuItem.uri }"
                        :key="'menuitem_'+index"
                        @click.native="closeMobileMenu"
                    >{{ menuItem.title }}</router-link>

                </template>
            </div>

            <router-link v-if="$store.state.showMenuLogo" class="logo" to="/">
                <logo :color="'#001E5A'"/>
            </router-link>
            <span v-else class="home-title">
                Rexroth Solution partner & Certified Excellence Service
            </span>

            <div class="menu-items secondary">
                <template v-for="(menuItem, index) in secondaryMenuItems"
                >
                    <SectorPopout v-if="menuItem.title === 'Sectoren'" :key="'menuitem_'+index"/>
                    <a v-else-if="menuItem.uri && menuItem.external" target="_blank" :href="menuItem.uri" :key="'menuitem_'+index">
                        {{ menuItem.title }}
                    </a>
                    <router-link
                        v-else-if="menuItem.uri"
                        :to="{ path: '/'+menuItem.uri }"
                        :key="'menuitem_'+index"
                        @click.native="closeMobileMenu"
                    >{{ menuItem.title }}</router-link>

                </template>
            </div>
        </nav>

        <nav class="main-nav mobile">

            <router-link class="logo" to="/" :class="{'show': $store.state.showMenuLogo}" @click.native="closeMobileMenu">
                <logo :color="'#001E5A'"/>
            </router-link>

            <div class="menu-toggle" @click="toggleMobileMenu">
                <svg>
                    <rect class="top"></rect>
                    <rect class="bottom"></rect>
                </svg>
                Menu
            </div>

            <div class="dropdown">
                <div class="menu-items">
                    <template v-for="(menuItem, index) in menuItems">
                        <template v-if="menuItem.title === 'Sectoren'">
                            <div :key="'menuItem_'+index" class="sector-wrapper" :class="{'open': mobileSectorMenuOpen}">
                                <span class="sector-toggle" @click="toggleMobileSectorMenu">Sectoren <span class="icon"><buttonArrowSVG color="#fff"/></span></span>
                                <div class="sector-dropdown">
                                    <span class="content">
                                        <router-link
                                            v-for="(link, index2) in sectorMenu"
                                            :key="'menuItem_dropdown_'+index2"
                                            :to="{ path: link.link.uri }"
                                            @click.native="closeMobileMenu"
                                        >
                                            {{link.title}}
                                        </router-link>
                                    </span>
                                </div>
                            </div>
                        </template>
                        <a v-else-if="menuItem.external" :key="index"
                            target="__blank"
                            ref="noopener noreferrer"
                            :href="menuItem.uri"
                        >{{ menuItem.title }}</a>
                        <router-link
                            v-else
                            :to="{ path: '/'+menuItem.uri }"
                            :key="'menuitem_'+index"
                            @click.native="closeMobileMenu"
                        >{{ menuItem.title }}</router-link>
                    </template>
                </div>
                <div class="menu-items secondary">
                    <template v-for="(menuItem, index) in secondaryMenuItems">
                        <template v-if="menuItem.title === 'Sectoren'">
                            <div :key="'menuItem_'+index" class="sector-wrapper" :class="{'open': mobileSectorMenuOpen}">
                                <span class="sector-toggle" @click="toggleMobileSectorMenu">Sectoren <span class="icon"><buttonArrowSVG color="#fff"/></span></span>
                                <div class="sector-dropdown">
                                    <span class="content">
                                        <router-link
                                            v-for="(link, index2) in sectorMenu"
                                            :key="'menuItem_dropdown_'+index2"
                                            :to="{ path: link.link.uri }"
                                            @click.native="closeMobileMenu"
                                        >
                                            {{link.title}}
                                        </router-link>
                                    </span>
                                </div>
                            </div>
                        </template>
                        <a v-else-if="menuItem.external" :key="index"
                            target="_blank"
                            ref="noopener noreferrer"
                            :href="menuItem.uri"
                        >{{ menuItem.title }}</a>
                        <router-link
                            v-else
                            :to="{ path: '/'+menuItem.uri }"
                            :key="'menuitem_'+index"
                            @click.native="closeMobileMenu"
                        >{{ menuItem.title }}</router-link>
                    </template>
                </div>
            </div>

        </nav>
    </div>
</template>

<script>
    import logo from '@/js/svg/logo.vue'
    import SectorPopout from '@/js/components/mainMenu/SectorPopout.vue'
    import WebshopPopout from '@/js/components/mainMenu/WebshopPopout.vue'
    import buttonArrowSVG from '@/js/svg/buttonArrow.vue'

    export default {
        components: {
            logo,
            SectorPopout,
            WebshopPopout,
            buttonArrowSVG
        },
        data() {
            return {
                position: 'absolute',
                show: 0,
                open: false,
                mobileSectorMenuOpen: false,
                mobileWebshopMenuOpen: false,
                toggles: 0,
                closeTimeout: null,
                onScrollTimeoout: null,
                lastScrollTop: 0
            };
        },
        computed: {
            'menuItems': function() {
                return this.$store.state.websiteData ? this.$store.state.websiteData.menu : null;
            },
            'secondaryMenuItems': function() {
                return this.$store.state.websiteData ? this.$store.state.websiteData.secondaryMenu : null;
            },
            showCurtains: {
                get() {
                    return this.$store.state.showCurtains;
                },
                set(state) {
                    this.$store.state.showCurtains = state;
                }
            },
            sectorMenu() {
                return this.$store.state.websiteData ? this.$store.state.websiteData.sectorMenu : null;
            },
            webshopMenu() {
                return this.$store.state.websiteData ? this.$store.state.websiteData.webshopMenu : null;
            }
        },
        methods: {
            toggleSectorPopup() {
                this.showSectorPopup = !this.showSectorPopup;
                this.showCurtains = this.showSectorPopup;

                if(this.showSectorPopup) {

                } else {

                }
            },
            toggleMobileMenu() {
                clearTimeout(this.closeTimeout);
                this.open = !this.open;

                if(this.open) {
                    this.toggles++;
                }
            },
            toggleMobileSectorMenu() {
                this.mobileSectorMenuOpen = !this.mobileSectorMenuOpen;
            },
            toggleMobileWebshopMenu() {
                this.mobileWebshopMenuOpen = !this.mobileWebshopMenuOpen;
            },
            closeMobileMenu() {
                clearTimeout(this.closeTimeout);
                this.closeTimeout = setTimeout(() => {
                    this.open = false;
                    this.mobileSectorMenuOpen = false;
                    this.mobileWebshopMenuOpen = false;
                }, 600);
            },
            menuOnScroll(e) {
                const doc = document.documentElement;
                const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

                if(top > 300) {
                    if (top > this.lastScrollTop){
                        this.show = 0;
                    } else {
                        this.show = 1;
                    }

                    this.lastScrollTop = top <= 0 ? 0 : top;
                    this.position = 'fixed show-animation';
                } else if(top > 250) {
                    this.position = 'fixed';
                } else {
                    this.position = 'absolute';
                    this.show = 0;
                }
            },
            onScroll(e) {
                    this.menuOnScroll(e);
            }
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        }
    };
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .main-nav-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        user-select: none;

        a {
            transition: color .25s cubic-bezier(0.65, 0, 0.35, 1);
        }

        &.fixed {
            position: fixed;

            &.show-animation {
                transition: transform .5s cubic-bezier(0.65, 0, 0.35, 1);
            }

            &:not(.open) {
                transform: translateY(-100%);

                &.show {
                    transform: translateY(0);
                }
            }
        }

        .main-nav.desktop {
            @include default-layout;
            min-height: 5rem;
            display: none;
            justify-content: space-between;
            align-items: center;
            color: $blue;
            @include font-reg;

            .curtains {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($blue, 0);
                transition: background-color 0.25s cubic-bezier(0.65, 0, 0.35, 1);
                pointer-events: none;

                &.show {
                    background-color: rgba($blue, 0.2);
                    pointer-events: all;
                }
            }

            .home-title {
                display: none;
                position: absolute;
                color: $white;
                left: 50%;
                transform: translate3d(-50%,0,0);

                @include desktopBig {
                    display: block;
                }
            }

            .logo {
                position: absolute;
                @include center;

                svg {
                    width: 100%;
                    height: auto;
                    max-width: 220px;
                }
            }

            .menu-items {
                display: flex;
                position: relative;

                > a, .popout {
                    color: $blue-light;
                    padding-right: $defaultPadding;

                    @include menuSwitch {
                        padding-right: $defaultPadding*1.5;
                    }

                    &:last-child {
                        padding: 0;
                    }
                }

                .logo, > a, .text, .home-title {
                    transition: opacity 0.25s cubic-bezier(0.65, 0, 0.35, 1);
                }

                > a {
                    text-decoration: none;
                    white-space: nowrap;

                    &:hover {
                        color: $blue;
                    }
                }
            }

            &.disabled {
                .logo, .menu-items a, .menu-items .text, .home-title {
                    opacity: .8;
                }
            }

            @include menuSwitch {
                display: flex;
            }
        }

        .main-nav.mobile {
            position: relative;
            padding: $defaultPadding;
            justify-content: space-between;
            @include defaultHref;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            transition: top .25s cubic-bezier(0.65, 0, 0.35, 1);


            .logo {
                overflow: hidden;
                flex: 1 1 100%;
                position: relative;
                z-index: 2;
                opacity: 0;
                margin-right: 1em;

                svg {
                    height: auto;
                    width: 100px;
                }

                &.show {
                    opacity: 1;
                }
            }

            .menu-toggle {
                flex: 1 0 auto;
                cursor: pointer;
                z-index: 2;

                svg {
                    height: .75em;
                    width: .75em;

                    rect {
                        height: 2px;
                        width: 100%;
                        transition: transform .2s cubic-bezier(0.65, 0, 0.35, 1);
                    }

                    rect.bottom {
                        transform: translateY(30%);
                    }

                    rect.top {
                        transform: translateY(70%);
                    }
                }
            }

            .dropdown {
                opacity: 0;
                width: 100%;
                height: 100vh;
                position: absolute;
                pointer-events: none;
                top: 0;
                left: 0;
                transition: opacity .25s cubic-bezier(0.65, 0, 0.35, 1);
                padding: 4.5rem $defaultPadding*2 $defaultPadding*2 $defaultPadding*2;

                .menu-items {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    opacity: 0;
                    transition-delay: .4s;
                    transition: opacity .5s cubic-bezier(0.65, 0, 0.35, 1);

                    a, .sector-toggle, .webshop-toggle{
                        @include font-big;
                        margin: 0 0 $defaultPadding/2 0;
                    }

                    .webshop-wrapper {
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: center;

                        .webshop-toggle {
                            display: flex;
                            justify-content: center;
                            flex: 0 0 100%;

                            .icon {
                                display: inline-block;
                                padding-left: $defaultPadding;

                                svg {
                                    transform: rotate(90deg);

                                    polygon {
                                        fill: currentColor;
                                    }
                                }
                            }
                        }

                        .webshop-dropdown {
                            display: flex;
                            flex-flow: column;
                            align-items: center;
                            max-height: 0;
                            overflow: hidden;
                            pointer-events: none;
                            transition: max-height .5s cubic-bezier(0.65, 0, 0.35, 1);

                            .content {
                                display: flex;
                                align-items: center;
                                flex-flow: column;

                                a {
                                    color: $blue-light;
                                }
                            }
                        }

                        &.open {
                            .webshop-toggle {
                                .icon {
                                    svg {
                                        transform: rotate(-90deg);
                                    }
                                }
                            }

                            .webshop-dropdown {
                                pointer-events: all;
                                max-height: 5rem;
                            }
                        }
                    }

                    .sector-wrapper {
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: center;

                        .sector-toggle {
                            display: flex;
                            justify-content: center;
                            flex: 0 0 100%;

                            .icon {
                                display: inline-block;
                                padding-left: $defaultPadding;

                                svg {
                                    transform: rotate(90deg);

                                    polygon {
                                        fill: currentColor;
                                    }
                                }
                            }
                        }

                        .sector-dropdown {
                            display: flex;
                            flex-flow: column;
                            align-items: center;
                            max-height: 0;
                            overflow: hidden;
                            pointer-events: none;
                            transition: max-height .5s cubic-bezier(0.65, 0, 0.35, 1);

                            .content {
                                display: flex;
                                align-items: center;
                                flex-flow: column;

                                a {
                                    color: $blue-light;
                                }
                            }
                        }

                        &.open {
                            .sector-toggle {
                                .icon {
                                    svg {
                                        transform: rotate(-90deg);
                                    }
                                }
                            }

                            .sector-dropdown {
                                pointer-events: all;
                                max-height: 5rem;
                            }
                        }
                    }
                }

                .lang-toggle {
                    position: absolute;
                    top: auto;
                    bottom: $defaultPadding;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            @include menuSwitch {
                display: none;
            }
        }

        &.open {
            .main-nav.mobile {
                // color: $white;

                // .logo svg g {
                //     fill: $white;
                // }

                .dropdown {
                    opacity: 1;
                    pointer-events: all;

                    .menu-items {
                        opacity: 1;

                    }
                }

                .menu-toggle {
                    z-index: 2;

                   svg {
                        rect {
                            transform-origin: center;
                        }

                        rect.bottom {
                            transform: translateY(50%) ;
                        }

                        rect.top {
                            transform: translateY(50%) ;
                        }
                    }
                }
            }
        }

        &.blue {
            background-color: $blue;

            .logo svg {
                path {
                    fill: $white;
                }
            }

            .menu-items {
                color: $white;

                > a {
                    &:hover {
                        color: $white !important;
                    }
                }
            }

            .menu-toggle {
                color: $blue-light;

                svg {
                    fill: $blue-light;
                }
            }

            .dropdown {
                background-color: $blue;
            }
        }

        &.blue-lighter {
            background-color: $blue-lighter;

            .dropdown {
                background-color: $blue-lighter;
            }
        }

        &.white {
            background-color: $white;

            .dropdown {
                background-color: $white;
            }
        }
    }
</style>
