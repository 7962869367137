<template>
    <section class="cta-component-home default-layout">
        <div class="grid-12">
            <div class="content-block">
                <div class="img-wrapper">
                    <div class="content">
                        <img :data-srcset="content.image.srcset" data-sizes="auto" class="lazyload">
                    </div>
                </div>
                <div class="text">
                    <span class="body" v-html="content.body"></span>
                    <div class="contact-details">
                        <div class="person">
                            <span class="about">
                                <strong>{{ content.name }}</strong><br>
                                {{ content.function }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

    export default {
        props: {
            index: {
                type: Number
            },
            content: {
                type: Object | Array,
                required: true
            }
        },
        computed: {
            
        },
        data() {
            return {
              
            };
        },
        methods: {
          
        },
        mounted() {
           
        }
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/helper.scss';

    .cta-component-home {
        .grid-12 {
            align-items: center;
            justify-items: center;

            .content-block {
                grid-column-end: span 1;
                @include grid-8;

                @include tablet {
                    grid-column: 3/span 8;
                }

                .text {
                    display: flex;
                    flex-flow: row wrap;
                    align-content: center;
                    @include font-reg;

                    .body {
                        @include font-big;
                        padding-top: 1em;
                        padding-bottom: .5em;

                        @include tablet {
                            padding-top: 0;
                        }
                    }

                    .person { 
                        display: flex;
                        align-items: flex-end;
                        flex-flow: row wrap;

                        a {
                            color: inherit;
                            text-decoration: none;
                        }

                        .about, .contact { 
                            flex: 1 0 100%;
                        }
                    }                    

                    @include tablet {
                        grid-column: 4/span 6;
                    }
                }

                .img-wrapper {
                    align-self: center;
                    @include aspect-ratio(1,1);

                    .content {
                        overflow: hidden;
                    }

                    img {
                        @include lazyloaded;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }

                    @include tablet {
                        grid-column: 1/span 3;
                    }
                }
            }
        }
    }
</style>