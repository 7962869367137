<template>
  <div id="app" v-if="!initializing">
    <MainMenu />
    <router-view/>
    <FooterElement />
    <Loader/>
    <Curtains/>
  </div>
</template>

<script>
  import MainMenu from '@/js/components/mainMenu/MainMenu'
  import FooterElement from '@/js/components/FooterElement'

  import Curtains from '@/js/components/Curtains'
  import Loader from '@/js/components/Loader'

  import { mapGetters, mapState } from 'vuex'

  export default {
      name: 'App',
      components: {
        MainMenu,
        FooterElement,
        Curtains,
        Loader
      },
      data: function() {
          return {
              firstRun: true,
              resizeTimeout: null,
          }
      },
      computed: {
        ...mapGetters([
            'langPrefix'
        ]),
        availableLanguages: function() {
            return this.$store.state.websiteData ? this.$store.state.websiteData.availableLanguages : null;
        },
        currentLanguage: {
            get() {
                return this.$store.state.currentLanguage;
            },
            set(language) {
                this.$store.commit('setCurrentLanguage', language)
            }
        },
        initializing: {
            get() {
                return this.$store.state.initializing;
            },
            set(bool) {
                this.$store.commit('setInitializing', bool)
            }
        },
        backgroundColor() {
          return this.$store.state.backgroundColor;
        }
      },
      methods: {
        init: function (startup) {
          if(!this.initializing || startup) {
            this.initializing = true;
            const startTime = Date.now();
            
            let currentLanguage        = this.$store.state.currentLanguage,
                websiteContentEndpoint = this.$store.state.apiEndpoints['website'].url+'?lang='+currentLanguage;

            this.$http.get(websiteContentEndpoint, {crossdomain: true})
                .then((result) => { 
                    this.content = result.data.data[0];
                    
                    // Check if enough time has passed to play the loading animation
                    const endTime = new Date(),
                          timeDiff = endTime - startTime;
        
                    const timeout = Math.max(0, 600-timeDiff);
                    
                    setTimeout(() => {
                        window.scrollTo(0,0);

                        // Set the content to the view component
                        this.$store.commit('setWebsiteData', result.data.data[0]);
                        this.initializing = false;

                    }, timeout);
                })
                .catch((error) => {
                    console.log(error);
                });
          }
        },
        setVH() {
          const vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
      },
      watch: {
        currentLanguage(lang) {
            this.init();
        }
      },
      created() {
        // Set the language on the first run
        this.$store.commit('setAvailableLanguages', window.availableLanguages);

        const lang = this.$route.params.part1;

        if(lang && window.availableLanguages.includes(lang)) {
            this.currentLanguage = lang;
        } else {
          this.currentLanguage = window.availableLanguages[0];
        }

        this.setVH();

        window.addEventListener('resize', () => {
          window.clearTimeout(this.resizeTimeout);
          this.resizeTimeout = setTimeout(() => {
            this.setVH();
          }, 200);
        });

        this.init(1);
      }
  };
</script>

<style lang="scss">
    @import '@/scss/base.scss';

    #app {
      overflow-x: hidden;
      min-height: 100vh;
    }
</style>