<template>
    <section class="default-layout projects-overview-component">
        <h4 v-if="!full">Onze cases</h4>
        <div class="projects-wrapper">            
            <ol>
                <li 
                    v-for="(project, index) in content.projects"
                    class="project"
                    :key="'item_'+index"
                >
                    <router-link :to="{ path: project.uri }">
                
                        <div class="thumbnail-wrapper">
                            <div class="content">
                                <img :data-srcSet="project.image.srcset" data-sizes="auto" class="lazyload">
                            </div>
                        </div>
                        <h3>{{ project.title }}</h3>
                        <span class="sub-title">{{ project.subTitle }}</span>
                    </router-link>
                </li>
            </ol>
        </div>
        <div v-if="!full" class="button-wrapper">
            <router-link :to="{ path: '/cases' }" class="button circle-arrow">
                Al onze cases
                <span class="icon">
                    <ButtonArrowSVG/>
                </span>
            </router-link>
        </div>
    </section>
</template>

<script>
    import ButtonArrowSVG from '@/js/svg/buttonArrow.vue';

    export default {
        components: {
            ButtonArrowSVG
        },
        props: {
            index: {
                type: Number
            },
            content: {
                type: Object | Array,
                required: true
            },
            nextComponentType: {
                type: String,
                required: false
            },
            full: {
                type: Boolean,
                required: false
            }
        },
        computed: {
            
        },
        data() {
            return {
              
            };
        },
        methods: {
          
        },
        mounted() {
           
        }
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/helper.scss';

    .projects-overview-component {
        margin-top: $defaultPadding*5;
        margin-bottom: $defaultPadding*5;

        h4 {
            @include font-big;
            padding-bottom: 0.5em;
        }

        .button-wrapper {
            display: flex;
            justify-content: flex-end;
            padding-top: $defaultPadding*1.5;
        }

        @include tablet {
            .button-wrapper {
                margin-top: -#{$defaultPadding*1.5};
            }
        }

        ol {
            list-style: none;
            display: grid;
            grid-template-columns: 1fr;
            gap: $defaultPadding*2;

            li.project {
                @include font-big;
                padding-bottom: $defaultPadding;
                transition: all .25s cubic-bezier(0.65, 0, 0.35, 1);

                h3 {
                    @include font-big;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                    
                    &:hover {
                        .sub-title {
                            color: $blue;
                        }
                    }
                }

                .thumbnail-wrapper {
                    margin-bottom: $defaultPadding;
                    overflow: hidden;
                    @include aspect-ratio(650, 380);
                    background-color: $grey;

                    img {
                        @include lazyloaded;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        transition: transform .9s cubic-bezier(0.65, 0, 0.35, 1);
                    }
                }

                .sub-title {
                    color: $blue-light;
                    transition: color .25s cubic-bezier(0.65, 0, 0.35, 1);
                }

                &:hover {
                    img {
                        transform: scale(1.018);
                    }
                }
            }

            @include tablet {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
</style>