<template>
    <div class="pagination">
            <a class="button circle-arrow reverse" @click="prevPage()" v-if="activePage !== 1">
                <span class="icon">
                    <ButtonArrowSVG/>
                </span>
            </a>
            <span @click="changePage(1)" :class="activePage === 1 ? 'active' : null" class="page-number">1</span>
            <div v-for="(page, index) in pages" :key="index">
                <span class="page-number" v-if="validateBeginDots(page)">...</span>
                <span @click="changePage(page)" class="page-number" :class="activePage === page ? 'active' : null" v-if="validatePagination(page)">{{ page }}</span>
                <span class="page-number" v-if="validateEndDots(page)">...</span>
            </div>
            <span @click="changePage(pages)" :class="activePage === pages ? 'active' : null" class="page-number">{{ pages }}</span>
            <a class="button circle-arrow" @click="nextPage()" v-if="activePage !== pages">
                <span class="icon">
                    <ButtonArrowSVG/>
                </span>
            </a>
    </div>
</template>

<script>
    import ButtonArrowSVG from '@/js/svg/buttonArrow.vue';

    export default {
        components: {
            ButtonArrowSVG
        },
        props: {
            pages: {
                type: Object | Array,
                required: true
            },
            currentPage: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                activePage: this.currentPage,
            };
        },
        methods: {
            changePage(pageNumber) {
                this.activePage= pageNumber;
                this.$store.commit('setNewsPagination', this.activePage);
            },
            nextPage() {
                if (this.activePage !== this.pages) {
                    this.changePage(this.activePage + 1);
                }
            },
            prevPage() {
                if (this.activePage !== 1) {
                    this.changePage(this.activePage - 1);
                }
            },
            validateBeginDots(page){
                return this.activePage !== 1
                    && this.activePage > 3
                    && (this.activePage === this.pages ? page === this.activePage - 3 : page === this.activePage - 2);
            },
            validatePagination(page){
                return page !== 1 && page !== this.pages
                    && (this.activePage === 1 ? this.activePage + 3 > page : this.activePage + 2 > page)
                    && (this.activePage === this.pages ? this.activePage -3 < page : this.activePage - 2 < page);
            },
            validateEndDots(page){
                return this.activePage !== this.pages
                    && this.activePage <= this.pages
                    && this.activePage + 2 !== this.pages
                    && ( this.activePage === 1 ? page === this.activePage + 3 : page === this.activePage + 2 );
            }
        },
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .pagination {
        display: flex;
        width: 100%;
        align-content: center;
        justify-content: flex-end;

        .reverse {
            transform: rotate(180deg);
        }

        .page-number {
            cursor: pointer;
            font-size: 20px;
            color: $blue;
            margin-right: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100vw;
            width: 44px;
            height: 44px;

        &.active {
            background-color: $blue;
            color: white;
        }

        &:hover {
            background-color: $blue;
            color: white;
        }

        &.none {
            display: none;
        }

    }

    }
</style>

